import axios from "axios"; 
import { authHeader } from '../_helpers';
export const customerService = {
    searchCustomer  
}; 
 

function searchCustomer(searchParma) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.post(`${window.$URL}search-customer`, searchParma, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
 
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text); 
        return data;
    });
}