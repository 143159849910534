import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import DefaultLayout from "../_layouts/default";
import AuthLayout from "../_layouts/auth"; 
import ReceiptLayout from "../_layouts/receipt";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isClientPortal,
  isPrivateAdmin,
  isGrantAccess,
  isReceipt,
  ...rest
}) {

  let checkTokenData = localStorage.getItem("userToken");  
  let checkToken = '';
  let loginRoleName = '';
 
  let signed;
  if(checkTokenData){
    checkToken = JSON.parse(checkTokenData);
    loginRoleName = checkToken.role; 
    if(checkToken){ 
      signed = checkToken.access_token ? true : false;
    }else{
      signed = false;
    }
}else{ 
  signed = false;
}
  
  let resetURL = window.location.pathname.split("/")[1]; 
  if (isPrivate && !signed) {
    return <Redirect to='/login' />;
  }
 

 let roleLayout = AuthLayout;
 if(loginRoleName === 'Admin'){
    roleLayout = AuthLayout; 
    if(isReceipt){
      roleLayout = ReceiptLayout;
    } 
    if (!isPrivate && signed) {
      return <Redirect to='/staff-panel' />;
    } 
    if(loginRoleName === 'Team_Member'){
      if (isClientPortal && signed) {
        return <Redirect to='/staff-panel' />;
      } 
      if (isPrivateAdmin && signed) {
        return <Redirect to='/staff-panel' />;
      } 
    }
 }else{
  roleLayout = DefaultLayout; 
 }
  const Layout = signed ? roleLayout : DefaultLayout;
 
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  isPrivate: false
};
