import React, { Component } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

const initialState = {
  loginBtnLoader:false
};
export default class ForgotPassword extends Component {
  state = {
    email: "",

    emailError: "",

    successMessage: "",
    success: false,
    errorMessage: "",
    error: false,
    token: "",
    btnLoader:false
  };
  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  validate = () => {
    this.setState({
      emailError: "",
    }); 
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    let emailError = "";

    if (this.state.email.length < 1) {
      emailError = "Please enter Email";
      this.setState({ 
        loginBtnLoader:false
      });
    } else if (!regex.test(this.state.email.trim())) {
      emailError = "Please enter a valid Email";
      this.setState({ 
        loginBtnLoader:false
      });
    }
    if (emailError) {
      this.setState({ emailError });
      return false;
    }

    this.setState({
      emailError,
    });
    return true;
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      success: false,
      error: false,
      successMessage: "",
      token: "",

      errorMessage: "",
    });
    let checkValidation = this.validate();
    if (checkValidation) {
      this.setState({ 
        loginBtnLoader:true
      });
      const { email } = this.state;
      let loginData = {
        email: email, 
      };
      this.setState({btnLoader:true});
      await axios
        .post(`${window.$URL}reset-password`, loginData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.success) { 
              this.setState(
                {
                  success: true,
                  successMessage: response.data.success,
                  token: response.data.passwordChangeToken, 
                },
                () => {
                  //this.props.history.push(`${process.env.PUBLIC_URL}/reset-password/${this.state.token}`);
                }
              );
              setTimeout(
                () => this.props.history.push(`/login`), 
                3000
                );
            } else { 
              this.setState({
                error: true,
                errorMessage: response.data.error,
              });
            }
            this.setState({btnLoader:false});
          }
        })
        .catch((error) => { 
          this.setState({btnLoader:false}); 
        });
    }else{
      this.setState({ 
        loginBtnLoader:false
      });
    }
  };

  render() {
    const { emailError, success, successMessage, errorMessage } = this.state;
    return (
      <div className="login-box"> 
        <div className="card card-outline card-primary">
          <div className="card-header text-center bg-primary">
            <img src={require('../../assets/images/logo.png')} alt="AdminLTE Logo" className="brand-image " />
          
          </div>
          <div className="card-body">
            <p className="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p> 
            <form onSubmit={this.onSubmit} autocomplete="on">
              <div className="input-group">
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
                <input type="email" name="email" className="form-control" placeholder="Email" onChange={this.onChange}
                  />  
              </div> 
              <p className="error-login text-red">{this.state.emailError}</p> 
              <div className="center">
                <button type="submit" className="btn btn-primary btn-block">
                <div className="buttonloader"><div className={`loader_wrap ${this.state.loginBtnLoader ? 'loader_active' : ''}`}><div className="preload"></div> </div></div>
                Get Reset Link</button>
              </div>
              <p className="mt-1">
                 <Link to="/Login">Login?</Link>  
              </p> 
            </form>
          </div> 
        </div> 
      </div>
    );
  }
}
