import React, { Component } from "react";
import axios from "axios";

const initialState = {
  loginBtnLoader:false
};
export default class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      // email: '',

      // emailError: '',

      successMessage: "",
      success: false,
      errorMessage: "",
      error: false,
      token: "",
      passwordError: "",
      confirmPasswordError: "",

      username: "",
      password: "",
      new_password:'',
      confirmPassword: "",
      update: "",
    };
  }

  async componentDidMount() {
    
    await axios
      .get(`${window.$URL}reset-password/${this.props.match.params.token}`)
      .then((response) => {
       
        if (response.data.message === "password reset link a-ok") {
          this.setState({
            username: response.data.data.otp,
            error: false,
          });
        } else if (response.data.message === "Password reset link is invalid") {
           
          this.setState({
            errorMessage: response.data.message,
            error: true,
          });
        } else {
          this.setState({
            errorMessage: response.data,
            error: true,
          });
        }
      })
      .catch((error) => {
        
       // console.log(error.data,'error');
      });
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  validate = () => {
    this.setState({
      passwordError: "",
      confirmPasswordError: "",
    });

    let passwordError = "";
    let confirmPasswordError = "";

    if (this.state.password.length < 1) {
      passwordError = "Please enter Password";
    } else if (this.state.password.length < 6) {
      passwordError = "Password must be 6 characters long";
      this.setState({ 
        loginBtnLoader:false
      });
    }

    if (passwordError) {
      this.setState({ passwordError });
      return false;
    }

    if (this.state.confirmPassword.length < 1) {
      confirmPasswordError = "Please enter  Confirm Password";
      this.setState({ 
        loginBtnLoader:false
      });
    } else if (this.state.confirmPassword !== this.state.password) {
      confirmPasswordError = "Must be same as password.";
      this.setState({ 
        loginBtnLoader:false
      });
    }
    if (confirmPasswordError) {
      this.setState({ confirmPasswordError });
      this.setState({ 
        loginBtnLoader:false
      });
      return false;
    }

    this.setState({
      passwordError,
      confirmPasswordError,
    });

    return true;
  };

  onSubmit = async (e) => {
    this.setState({loginBtnLoader:true});
    e.preventDefault();
    this.setState({
      success: false,
      error: false,
      successMessage: "",
      errorMessage: "",
    });
    let checkValidation = this.validate(); 
    if (checkValidation) { 
      axios
        .post(`${window.$URL}update-password`, {
          otp: this.state.username,
          new_password: this.state.password,
        })
        .then((response) => { 
          if (response.data.success) {
           
            this.setState({
              success: true,
              successMessage: response.data.success,
            });
            this.props.history.push('/login');
          } else {
            this.setState({
              error: true,
            });
          }
        })
        .catch((error) => { 
        });
    }else{
      this.setState({ 
        loginBtnLoader:false
      });
    }
  };

  render() {
    const {
      passwordError,
      confirmPasswordError,
      success,
      error,
      successMessage,
      errorMessage,
    } = this.state;
    return (
      <div className="login-box"> 
        <div className="card card-outline card-primary">
          <div className="card-header text-center bg-primary">
            <img src={require('../../assets/images/logo.png')} alt="AdminLTE Logo" className="brand-image " />
          
          </div>
          <div className="card-body">
            <p className="login-box-msg">Update password and login</p> 
            <form onSubmit={this.onSubmit} autocomplete="on"> 
              <div className="input-group">
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
                <input type="password" name="password" className="form-control" placeholder="Password" onChange={this.onChange}/>
              </div>  
              <p className="error-login text-red">{passwordError}</p>
              <div className="input-group">
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
                <input type="password" name="confirmPassword" className="form-control" placeholder="Password" onChange={this.onChange}/>
              </div>  
              <p className="error-login text-red">{confirmPasswordError}</p>
              <div className="center">
                <button type="submit" className="btn btn-primary btn-block">
                <div className="buttonloader"><div className={`loader_wrap ${this.state.loginBtnLoader ? 'loader_active' : ''}`}><div className="preload"></div> </div></div> 
                Set New Password</button>
              </div> 
            </form>
          </div> 
        </div> 
      </div> 
    );
  }
}
