import React, { useState } from 'react';
     
const PrintCustomerReceipt = (props) => { 
const goldItems = props.data.goldItems;
const goldDetails = props.data.goldDetails;
const ownerDetail = props.data.ownerDetail;
const trasactionDetail = props.data.trasactionDetail;  
  return (
      <div className="receipt-print-wrap"> 
        <table style={{ width:"100%",borderCollapse: "collapse",cellspacing:"0" }}>
          <tbody>
            <tr>
                <td ><img src={require('../../assets/images/logo.png')} alt="" className="print-logo" /></td>
                <td style={{ width: "30%"}}>
                    <p className=""> <strong>Gold Secure</strong> </p> 
                    <p className="p-text">ABN: 16 608 402 188 </p> 
                    <p className="p-text">Corner Villa St & Ipswich Road, 663</p>  
                    <p className="p-text">Ipswich Road </p> 
                    <p className="p-text">Annerley, Queensland, Australia, 4103</p>  
                    <p className="p-text">Phone: (07) 3123 6677</p> 
                </td>
                <td style={{ width: "30%",textAlign:"right"}}><p style={{ fontSize :"35px",fontWeight:"bold"}}>Entry #: {trasactionDetail.docket_no}</p></td>
            </tr>
          </tbody>
        </table> 
        <h4 style={{ fontSize :"30px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>Purchase Receipt</h4> 
        <table className="print-tbl-border"  style={{ width:"98%",margin:"0px 10px" }}>
          <tbody>
            <tr>
                <td colSpan="2" style={{ border:"none" }}>
                    <p style={{ fontWeight:"bold" }}>CUSTOMER DETAILS:</p>
                    <p>{ownerDetail.cust_name}</p>
                    <p>{ownerDetail.street_1} {ownerDetail.street_2} {ownerDetail.town}, {ownerDetail.state}</p>
                    <p>{ownerDetail.country} - {ownerDetail.postal_code}</p>
                </td>
                <td colSpan="2">
                    <p>Docket #: {trasactionDetail.docket_no}</p>
                    <p>Date: {trasactionDetail.trasaction_time}</p>
                </td>
            </tr>
          </tbody>
        </table>
        <table className="print-tbl-border"  style={{ width:"98%",margin:"20px 10px" }}>
          <tbody>
              <tr>
                  <td colSpan="5">GOLD SALES INFORMATION</td>
              </tr>
              <tr>
                  <td>GOLD TYPE</td>
                  <td>PURITY</td>
                  <td>GROSS</td>
                  <td>NET</td> 
                  <td>Value</td> 
              </tr>
              {goldItems.length > 0 && goldItems.map((data, index) => {
                return (  
                          <tr>
                              <td>{data.gold_type}</td>
                              <td>{data.purity}</td>
                              <td>{data.gross}</td>
                              <td>{data.net}</td> 
                              <td>{data.value}</td> 
                          </tr>
                       )
                  }) 
              }   
              <tr>
                  <td></td>
                  <td>TOTAL GOLD:</td>
                  <td>{goldDetails.finaltotalGrossGold}</td>
                  <td>{goldDetails.finaltotalNetGold}</td>
                  <td>${goldDetails.finalGoldAmount} AUD</td> 
              </tr>
              <tr>
                  <td colSpan="4">TOTAL CUSTOMER PAYMENT :</td>
                  <td>${goldDetails.finalGoldAmount} AUD</td> 
              </tr> 
            </tbody>
        </table>
        <div style={{borderTop:"1px dotted #000"}}></div>
        <table className="print-tbl-border"  style={{ width:"98%",margin:"20px 10px" }}>
          <tbody> 
            <tr>
                <td colSpan="5">Payment Method Information:</td>
            </tr>
            <tr> 
                <td colSpan="5">
                   <p>Payment Method:CASH PAID</p>
                      <p>TOTAL $AUD:{trasactionDetail.amount}</p> 
                </td> 
            </tr> 
            <tr>
              <td colSpan="3">Date / Time of Payment(s):</td>
              <td colSpan="2">{trasactionDetail.trasaction_time}</td>
            </tr>
            <tr>
              <td colSpan="5">Declared ownership by: {trasactionDetail.created_name}</td>
            </tr>
          </tbody>
        </table>
        <table className="print-tbl-border"  style={{ width:"98%",margin:"20px 10px" }}> 
          <tbody>
            <tr>
              <td colSpan="3">CUSTOMER IDENTITY INFORMATION</td>
              <td colSpan="2">ITEM(S) DESCRIPTION</td>
            </tr>
            <tr> 
              <td colSpan="">
                 <p>Photo ID Type:</p>  
                  <p>Photo ID #:</p> 
                  <p>Photo ID Expiry:</p> 
                  <p>Photo ID Date of Birth:</p> 
                  <p>Photo ID Country:</p>  
              </td> 
              <td colSpan=""> 
                  <p>{ownerDetail.doc_type}</p>  
                  <p>{ownerDetail.id_number}</p> 
                  <p>{ownerDetail.expiry_date}</p> 
                  <p>{ownerDetail.dob}</p> 
                  <p>{ownerDetail.country_issue}</p>  
              </td>
              <td colSpan="2"> 
                  <p>{trasactionDetail.desc_purchase}</p>  
                  <p style={{fontWeight:"bold"}}>Item(s) Hallmark(s) / Identifiable  Features</p>  
                  <p>{trasactionDetail.halmark}</p>  
              </td>  
            </tr> 
          </tbody>
        </table>
        <table className="print-tbl-border"  style={{ width:"98%",margin:"20px 10px",textAlign:"center" }}> 
          <tbody>
            <tr> 
              <td colSpan="5">Your payment(s) were processed today by: <strong>{trasactionDetail.created_name}</strong></td>
            </tr>
          </tbody>
      </table> 
    </div>
   
  );
}
export default PrintCustomerReceipt