import React, { useState } from 'react';
     
const MelboumePreviewReceipt = (props) => { 
const scrap = props.data;
const metalJoGold = props.metalJobGold;
const metalJobSliver = props.metalJobSliver;
const customer = props.customer;
const caratGoldTotal = props.caratGoldTotal;
const caratSliverTotal = props.caratSliverTotal;
const salesSummery = props.salesSummery;
const meltShareSummery = props.meltShareSummery;
  
  return (
      <div className="receipt-print-wrap mt-15"> 
        <table style={{ width:"99%",borderCollapse: "collapse",cellspacing:"0" }}>
            <tbody>
                <tr>
                    <td style={{ width: "20%"}}><img src={require('../../assets/images/logo.png')} alt="" className="print-logo" /></td>
                    <td style={{ width: "15%"}}></td>
                    <td style={{ width: "30%"}}>
                        <p className=""> <strong>Gold Secure</strong> </p> 
                        <p className="p-text">ABN: 16 608 402 188 </p> 
                        <p className="p-text">Corner Villa St & Ipswich Road, 663</p>  
                        <p className="p-text">Ipswich Road </p> 
                        <p className="p-text">Annerley, Queensland, Australia, 4103</p>  
                        <p className="p-text">Phone: (07) 3123 6677</p> 
                    </td>
                    
                </tr>
            </tbody>
        </table>   
        <table style={{ width:"99%",borderCollapse: "collapse",cellspacing:"0" }}>
            <tbody>
                <tr>
                    <td colSpan="2" style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>PREVIEW: Melt Job Tracking Receipt (PREVIEW ONLY)</td>
                </tr>
                <tr>
                    <td style={{ fontSize :"20px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>
                        <p>Date: {scrap.curr_date}</p>
                        <p>Average Gold Spot: ${scrap.gold_average}</p>
                        <p>Average Silver Spot: $21.80</p>
                    </td>
                    <td  style={{ fontSize :"20px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>
                        <p>Melt Job ID #: PREVIEW ONLY</p>
                        <p>Tracking #: PREVIEW ONLY</p>
                    </td>
                </tr>
            </tbody>
        </table>
        <table style={{ width:"99%" }}>
            <tbody>
                <tr>
                    <td colSpan="5"  style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>MELT JOB SUMMARY FROM BRISBANE (Processed By Gagan)</td>
                </tr>
                <tr style={{ background:"#D7D9D7",border:'1px solid #000',padding:"5px;",textAlign:'center',fontWeight:'bold' }}>
                    <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                        <p>METAL TYPE</p>
                    </td>
                    <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                        <p>PAYMENTS MADE</p>
                    </td>
                    <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                        <p>GROSS WEIGHT</p>
                    </td>
                    <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                        <p>NET WEIGHT ESTIMATE</p>
                    </td>
                    <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                        <p>EXPECTED ASSAY</p>
                    </td>
                </tr>  
                <tr>
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>Gold</td>
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>${metalJoGold.payment_mode}</td> 
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{metalJoGold.gross_weight}g</td> 
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{metalJoGold.net_weight}g</td> 
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{metalJoGold.expected_assay}%</td> 
                </tr> 
                
                <tr>
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>Silver</td>
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>${metalJobSliver.payment_mode}</td> 
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{metalJobSliver.gross_weight}g</td> 
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{metalJobSliver.net_weight}g</td> 
                    <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{metalJobSliver.expected_assay}%</td> 
                </tr>
                <tr>
                    <td style={{ display:"none;" }}></td>
                    <td colSpan="2" style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>TOTAL PAYMENTS MADE:</td>
                    <td colSpan="2" style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>${scrap.total_payment_made} AUD</td>
                </tr>
            </tbody>
        </table>
        <table style={{ width:"99%" }}>
            <tbody>
                <tr>
                    <td colSpan="9" style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>GOLD CARAT / PURITY TOTALS</td>
                </tr>             
                {caratGoldTotal.length > 0 && caratGoldTotal.map((data, index) => {
                   return ( <>
                            <tr>
                                <td>
                                    {data.length > 0 && data.map((data2, index2) => {
                                       return (
                                               <div style={{width:'10%',display: 'inline-block',marginLeft:'2px',padding:"5px;",textAlign:'center',fontWeight:'bold' }}>
                                                    <p style={{ background:"#D7D9D7",border:'1px solid #000' }}>{data2.carat}</p>
                                                    <p style={{ border:'1px solid #000',borderTop:'unset' }}>{data2.total_gram}g</p>
                                                </div>
                                             )
                                        }) 
                                    }
                                </td> 
                            </tr>
                            </> 
                         )
                    }) 
                } 
            </tbody>
        </table>
        <table style={{ width:"99%" }}>
            <tbody>
                <tr>
                    <td colSpan="9" style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>SLIVER CARAT / PURITY TOTALS</td>
                </tr>             
                {caratSliverTotal.length > 0 && caratSliverTotal.map((data, index) => {
                   return ( <>
                            <tr>
                                <td>
                                    {data.length > 0 && data.map((data2, index2) => {
                                       return (
                                               <div style={{padding:"5px;",width:'10%',display: 'inline-block',marginLeft:'2px',textAlign:'center',fontWeight:'bold' }}>
                                                    <p style={{ background:"#D7D9D7",border:'1px solid #000' }}>{data2.carat}</p>
                                                    <p style={{ border:'1px solid #000',borderTop:'unset' }}>{data2.total_gram}g</p>
                                                </div>
                                             )
                                        }) 
                                    }
                                </td> 
                            </tr>
                            </> 
                         )
                    }) 
                } 
            </tbody>
        </table>
        <table style={{ width:"99%" }}>
            <tbody>
                <tr>
                    <td colSpan="6" style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>INCLUDED SALES SUMMARY</td>
                </tr>
                <tr style={{ textAlign:'center',fontSize :"20px",fontWeight:"bold",border:'1px solid #000',background:'#D5D9D6' }}>
                    <td style={{ border:'1px solid #000' }}>DOCKET</td>
                    <td style={{ border:'1px solid #000' }}>DATE</td>
                    <td style={{ border:'1px solid #000' }}>STAFF</td>
                    <td style={{ border:'1px solid #000' }}>FINE GOLD</td>
                    <td style={{ border:'1px solid #000' }}>FINE SILVER</td>
                    <td style={{ border:'1px solid #000' }}>PAYMENT</td>
                </tr> 
                {salesSummery.length > 0 && salesSummery.map((data, index) => {
                   return ( <>
                            <tr  style={{ textAlign:'center' }}>
                                <td style={{ border:'1px solid #000' }}>{data.docket_no}</td> 
                                <td style={{ border:'1px solid #000' }}>{data.sale_date_time}</td> 
                                <td style={{ border:'1px solid #000' }}>{data.staff}</td> 
                                <td style={{ border:'1px solid #000' }}>{data.fine_gold}</td> 
                                <td style={{ border:'1px solid #000' }}>{data.fine_sliver}</td> 
                                <td style={{ border:'1px solid #000' }}>{data.payment}</td> 
                            </tr>
                            </> 
                         )
                    }) 
                }  
            </tbody>
        </table> 
        {meltShareSummery.length > 0 && meltShareSummery.map((data, index) => {
           return ( <>
                <table style={{ width:"99%" }}>
                    <tbody>
                        <tr>
                            <td colSpan="6" style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>{data.name} SHARE SUMMARY</td>
                        </tr> 
                        <tr style={{ background:"#D7D9D7",border:'1px solid #000',padding:"5px;",textAlign:'center',fontWeight:'bold' }}>
                            <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                                <p>METAL TYPE</p>
                            </td>
                            <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                                <p>PAYMENTS MADE</p>
                            </td>
                            <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                                <p>GROSS WEIGHT</p>
                            </td>
                            <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                                <p>NET WEIGHT ESTIMATE</p>
                            </td>
                            <td style={{ border:'1px solid #000',borderTop:'unset' }}>
                                <p>EXPECTED ASSAY</p>
                            </td>
                        </tr>                    
                        {data.summery.gold  ?  
                            <tr>                                
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.gold.metal_type}</td> 
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.gold.payment_made}</td> 
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.gold.gross_weight}</td> 
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.gold.net_weight}</td> 
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.gold.expected_say}%</td>  
                            </tr>
                        : ''}
                        {data.summery.sliver  ? 
                            <tr>                                
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.sliver.metal_type}</td> 
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.sliver.payment_made}</td> 
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.sliver.gross_weight}</td> 
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.sliver.net_weight}</td> 
                                <td style={{ padding:"5px;",textAlign:'center',fontWeight:'bold',border:'1px solid #000',borderTop:'unset' }}>{data.summery.sliver.expected_say}%</td>  
                            </tr> 
                        : ''}
                    </tbody>
                </table>
                {data.gold.length > 0 ?
                    <table style={{ width:"99%" }}>
                        <tbody>
                            <tr>
                                <td colSpan="6" style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>{data.name} GOLD CARAT PURITY TOTALS</td>
                            </tr> 
                            <tr>
                                {data.gold.length > 0 && data.gold.map((data2, index2) => {
                                    return (
                                            <>
                                            {data2.length > 0 && data2.map((data3, index3) => {
                                                return (
                                                       <div style={{padding:"5px;",width:'10%',display: 'inline-block',marginLeft:'2px',textAlign:'center',fontWeight:'bold' }}>
                                                            <p style={{ background:"#D5D9D6",border:'1px solid #000' }}>{data3.carat}</p>
                                                            <p style={{ border:'1px solid #000',borderTop:'unset' }}>{data3.total_gram}g</p>
                                                        </div>
                                                    )
                                                }) 
                                            }
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                    : ''}
                </> 
                 )
            }) 
        }   
        {meltShareSummery.length > 0 && meltShareSummery.map((data, index) => {
           return ( <>
                {data.sliver.length > 0 ?
                    <table style={{ width:"99%" }}>
                        <tbody>
                            <tr>
                                <td colSpan="6" style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>{data.name} SLIVER CARAT PURITY TOTALS</td>
                            </tr> 
                            <tr>
                                {data.sliver.length > 0 && data.sliver.map((data2, index2) => {
                                    return (
                                            <>
                                            {data2.length > 0 && data2.map((data3, index3) => {
                                                return (
                                                       <div style={{padding:"5px;",width:'10%',display: 'inline-block',marginLeft:'2px',textAlign:'center',fontWeight:'bold' }}>
                                                            <p style={{ background:"#D5D9D6",border:'1px solid #000' }}>{data3.carat}</p>
                                                            <p style={{ border:'1px solid #000',borderTop:'unset' }}>{data3.total_gram}g</p>
                                                        </div>
                                                    )
                                                }) 
                                            }
                                            </>
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                    : ''}
                    </> 
                 )
            }) 
        } 

        <p style={{ fontSize :"23px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px",textAlign:'center' }}> INTERNAL USE ONLY</p>  
    </div>
   
  );
}
export default MelboumePreviewReceipt

