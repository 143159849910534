import React, { useState } from 'react';
     
const PrintReceipt = (props) => { 
    const scrap = props.data;
  return (
      <div className="receipt-print-wrap"> 
        <table  style={{ width:"98%",margin:"0px 10px" }}>
            <tbody>
                <tr  style={{ width:"90%" }}>
                    <td><img src={require('../../assets/images/logo.png')} alt="" className="print-logo" /></td>
                    <td style={{ width: "30%"}}>
                        <p className=""> <strong>Gold Secure</strong> </p> 
                        <p className="p-text">ABN: 16 608 402 188 </p> 
                        <p className="p-text">Corner Villa St & Ipswich Road, 663</p>  
                        <p className="p-text">Ipswich Road </p> 
                        <p className="p-text">Annerley, Queensland, Australia, 4103</p>  
                        <p className="p-text">Phone: (07) 3123 6677</p> 
                    </td>
                    <td style={{ width: "30%",textAlign:"right"}}><p style={{ fontSize :"35px",fontWeight:"bold"}}>Entry #: {scrap.trasaction_detail.docket_no}</p></td>
                </tr>
            </tbody>
        </table> 
        <h4 className="receipt-title" style={{ fontSize :"30px",padding:"30px 0px",fontWeight:"bold",margin:"0px 10px" }}>TRANSACTIONS REGISTER FOR SECOND-HAND GOODS</h4> 
        <table className="print-tbl-border"  style={{ width:"98%",margin:"0px 10px" }}>
            <tbody>
                <tr>
                    <th  className="receipt-title" colSpan="2" style={{ fontSize :"22px",padding:"8px",fontWeight:"bold" }}>Transaction details</th>
                </tr>
                <tr>
                    <td>Entry number</td>
                    <td>{scrap.trasaction_detail.docket_no}</td>
                </tr>
                <tr>
                    <td>Time and date of transaction (indicate whether goods were purchased or  otherwise acquired)</td>
                    <td>PURCHASED {scrap.trasaction_detail.trasaction_time}</td>
                </tr>
                <tr>
                    <td>Description of second-hand goods</td>
                    <td>scrap piece</td>
                </tr>
                <tr>
                    <td>Brand name, model number and serial number of  the property</td>
                    <td>{scrap.trasaction_detail.brand_modal}</td>
                </tr>
                <tr>
                    <td>Name and position of person who made the entry  for acquiring the second-hand goods </td>
                    <td>{scrap.trasaction_detail.created_name}</td>
                </tr>
                <tr>
                    <th  className="receipt-title" colSpan="2" style={{ fontSize :"22px",padding:"8px",fontWeight:"bold" }}>Owner details</th>
                </tr>
                <tr>
                    <td>Name of person from whom goods were purchased  or otherwise acquired </td>
                    <td>{scrap.owner_detail.cust_name}</td>
                </tr>
                <tr>
                    <td>Address of person from whom goods were  purchased or otherwise acquired</td>
                    <td>{scrap.owner_detail.street_1} {scrap.owner_detail.street_2} {scrap.owner_detail.town}, {scrap.owner_detail.state}, {scrap.owner_detail.country} - {scrap.owner_detail.postal_code}</td>
                </tr>
                <tr>
                    <td>Verification of name and address  e.g. drivers licence number </td>
                    <td>{scrap.owner_detail.doc_type} ({scrap.owner_detail.country_issue}):  {scrap.owner_detail.id_number} EXP: {scrap.owner_detail.expiry_date} </td>
                </tr>
                <tr>
                    <td>Is the person the owner of the property?  If no, the name and address of owner and details of  how person acquired the property</td>
                    <td>{scrap.owner_detail.owan_property}</td>
                </tr>
                <tr>
                    <th  className="receipt-title" colSpan="2" style={{ fontSize :"22px",padding:"8px",fontWeight:"bold" }}>Purchaser details</th>
                </tr>
                <tr>
                    <td>Date sold</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Amount paid upon sale </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Name of purchaser</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Address of purchaser</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Exchange of property  (description and date) </td>
                    <td></td>
                </tr>
                <tr>
                    <td>Disposal of property by other means (description and date)</td>
                    <td></td>
                </tr>
                <tr>
                    <td>Name and position of person who made the entry  for the sale/exchange or disposal of the second-  hand goods</td>
                    <td></td>
                </tr>
                <tr>
                    <th colSpan="2"  className="receipt-title" style={{ fontSize :"22px",padding:"8px",fontWeight:"bold" }}>END OF DATA FOR ENTRY # {props.data.trasaction_detail.docket_no}</th>
                </tr>
            </tbody>
        </table> 
    </div>
   
  );
}
export default PrintReceipt