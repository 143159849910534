import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { scrapService,configService,customerService } from '../../_services';  
import PrintReceipt from '../../component/PrintReceipt';  
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default class CustomerDetails extends Component { 
  constructor(props) {
    super(props); 
      this.state = {
           scrap_id:'',
           customer_search:[],
           custom_scraps:[],
           scrapTotal:'',
           customers:[],
           customers:[],
           configPaymentTypes:[],
           configDocument:[],
           configTitle:[],
           customer:{
            id:'',
            title:'',
            first_name:'', 
            middle_name:'', 
            surname:'', 
            business_name:'', 
            business_abn:'', 
            phone:'', 
            street_1:'', 
            street_2:'', 
            town:'', 
            state:'', 
            postal_code:'', 
            country:'', 
            doc_type:'', 
            country_issue:'', 
            id_number:'', 
            dob:'', 
            expiry_date:'', 
            text_customer_id:'',
            text_based_id:'' 
           }, 
           sale_date:'',
           sale_time:'',
           payment_type_1:'',
           payment_type_2:'',
           payment_type_3:'',
           payment_type_4:'',
           payment_type_5:'',
           payment_type_6:'',
           payment_type_7:'',
           payment_type_8:'',
           cash_paid:'',
           halmark:'',
           dec_purchase_item:'',
           payment_note:'',
           docket_no:'',
          'validationErrors':{
            titleError: '', 
            first_nameError: '', 
            surnameError:'',
            street_1Error:'',
            townError:'',
            stateError:'',
            postalCodeError:'',
            countryError:'',
            saleDateError:'',
            saleTimeError:'',
            docTypeError:'',
            countryIssueError:'',
            idNumberError:'',
            dobError:'',
            expiryDateError:'',
            textCustomerIdError:'',
            docketNoError:''
          },
          selectCustomerList:true,
      }  
  };  
  async componentDidMount(){
     let checkTokenData = localStorage.getItem("userToken");
      if (this.props.location.state) { 
          this.setState({
              scrap_id:this.props.location.state.scrap_id,
              custom_scraps:this.props.location.state.custom_scraps,
              scrapTotal:this.props.location.state.scrapTotal,
              cash_paid:this.props.location.state.scrapTotal,
            });
      }else{
        this.props.history.push(`/scrap-quote`);
      }  
      if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData);
            //
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            });
            configService.getConfig('payment_type=true&title=true&document=true')
            .then(async (result) => {
                let responseJson = result;  
                if(responseJson.status){
                  let finalData = responseJson.data;  
                  this.setState({
                    configPaymentTypes:finalData.payment_type,
                    configTitle:finalData.title,
                    configDocument:finalData.document,
                  }); 
                } 
            });
        }
  }
  onSearchChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
       const { customer_search } = this.state;
        this.setState({
          customer_search: {
                ...customer_search,
                [name]: value
            }
        }); 
  }
  onCustomerInputChnage = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
       const customer = this.state.customer;
        this.setState({
          customer: {
                ...customer,
                [name]: value
            }
        }); 
  } 
  onInputChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target;  
        this.setState({ 
                [name]: value 
        }); 
  }  
  handleDateChange = (name,date) => {  
    if(date){
      this.setState({
        [name]: moment(moment(date)), 
      })
    }else{
        this.setState({
            [name]: "", 
          })
    }
  }
  handleExpiryDateChange = (name,date) => {  
     const { customer } = this.state;
        
    if(date){
       this.setState({
          customer: {
                ...customer,
                [name]: moment(moment(date)),
            }
        });
    }else{
         this.setState({
          customer: {
                ...customer,
                [name]: '',
            }
        });
    }
  }
  handleDOBDateChange = (name,date) => {  
     const { customer } = this.state; 
    if(date){
       this.setState({
          customer: {
                ...customer,
                [name]: moment(moment(date)),
            }
        });
    }else{
         this.setState({
          customer: {
                ...customer,
                [name]: '',
            }
        });
    }
  }
  validate = () => {
      let validateStatus = true; 
      const onlyNumberRegExp = /^[0-9\b]+$/;
      
      if (this.state.customer.title < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{titleError:'Please select title'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{titleError:''}));
      }
      if (this.state.customer.first_name < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{firstNameError:'Please enter first name'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{firstNameError:''}));
      } 
      if (this.state.customer.street_1 < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{street_1Error:'Please enter address'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{street_1Error:''}));
      }
      if (this.state.customer.town < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{townError:'Please enter town'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{townError:''}));
      } 
      if (this.state.customer.state < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{stateError:'Please enter state'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{stateError:''}));
      } 
     
      if (this.state.customer.postal_code.length < 1) { 
          this.setState(Object.assign(this.state.validationErrors,{postalCodeError:'Please Enter PostalCode'}));
          validateStatus = false;
      }else if (!onlyNumberRegExp.test(this.state.customer.postal_code)){
          this.setState(Object.assign(this.state.validationErrors,{postalCodeError:'Please Enter Valid PostalCode'}));
          validateStatus = false;
      }else{
          this.setState(Object.assign(this.state.validationErrors,{postalCodeError:''}));
      }
      if (this.state.customer.country < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{countryError:'Please enter country'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{countryError:''}));
      }
      if (this.state.customer.doc_type < 1) {   
        console.log(this.state.customer.doc_type);
          this.setState(Object.assign(this.state.validationErrors,{docTypeError:'Please enter doc type'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{docTypeError:''}));
      }
      if (this.state.customer.country_issue < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{countryIssueError:'Please enter country issue'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{countryIssueError:''}));
      } 
      if (this.state.customer.id_number < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{idNumberError:'Please enter id number'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{id_numberError:''}));
      }
      if (this.state.customer.dob < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{dobError:'Please enter DOB'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{dobError:''}));
      } 
      if (this.state.customer.expiry_date < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{expiryDateError:'Please enter expiry date'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{expiryDateError:''}));
      } 
      if (this.state.customer.text_customer_id < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{textCustomerIdError:'Please enter Text ID Customer'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{textCustomerIdError:''}));
      }
      if (this.state.sale_date < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{saleDateError:'Please enter date of sale'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{saleDateError:''}));
      }
      if (this.state.sale_time < 1) { 
          this.setState(Object.assign(this.state.validationErrors,{saleTimeError:'Please enter time of sale'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{saleTimeError:''}));
      }
      if (this.state.docket_no < 1) { 
          this.setState(Object.assign(this.state.validationErrors,{docketNoError:'Please enter docket no'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{docketNError:''}));
      }
    return validateStatus; 
  } 
  searchCustomer = (e) => {
      const customerSearch = this.state.customer_search;
      let searchParma = {
          'surname':customerSearch.search_surname,
          'first_name':customerSearch.search_first_name,
          'photo_id':customerSearch.search_photo_id,
          'postal_code':customerSearch.search_postal_code,
          'postal_customer_type':customerSearch.search_customer_type,
      };
      customerService.searchCustomer(searchParma)
        .then(async (result) => {
            let responseJson = result;  
            if(responseJson.status){ 
                  let finalData = responseJson.data.data;  
                  this.setState({customers:finalData}); 
            } 
      });
        this.setState({selectCustomerList:true});
  } 
   onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(new RegExp("[0-9]").test(keyValue)))
      event.preventDefault();
    return;
  }
  searchCustomerClear = (e) => {
      let searchParma = {
          'search_surname':'',
          'search_first_name':'',
          'search_photo_id':'',
          'search_postal_code':'',
          'search_customer_type':'',
      };
      this.setState({
        customers:[],
        customer_search:searchParma,
      }); 

  } 
  selectCustomer = (e,x) => { 
     let customerData = this.state.customers[x]; 
    this.setState({
      customer: { 
        id:customerData.id,
        title:customerData.title,
        first_name:customerData.first_name, 
        middle_name:customerData.middle_name, 
        surname:customerData.surname, 
        business_name:customerData.business_name, 
        business_abn:customerData.business_abn, 
        phone:customerData.phone, 
        street_1:customerData.street_1, 
        street_2:customerData.street_2, 
        town:customerData.town, 
        state:customerData.state, 
        postal_code:customerData.postal_code, 
        country:customerData.country, 
        doc_type:customerData.doc_type, 
        country_issue:customerData.country_issue, 
        id_number:customerData.id_number, 
        dob:customerData.dob, 
        expiry_date:customerData.expiry_date, 
        text_customer_id:customerData.text_customer_id,
        text_based_id:customerData.text_based_id  
      },
      'disabled' : true,
    });
    //this.forceUpdate(); 
    this.setState({selectCustomerList:false});
  } 
  selectCustomerClear = () => {  
    this.state.disabled = false;
    this.forceUpdate(); 
  } 
  onSaveCustomerOrder = (e) => {
    e.preventDefault();
    let validate = this.validate();
    let customerParma =  {
       scrap_id: this.state.scrap_id, 
       customer: this.state.customer, 
       sale_date:this.state.sale_date,
       sale_time:this.state.sale_time,
       dec_purchase_item:this.state.dec_purchase_item,
       payment_type_1:this.state.payment_type_1,
       payment_type_2:this.state.payment_type_2,
       payment_type_3:this.state.payment_type_3,
       payment_type_4:this.state.payment_type_4,
       payment_type_5:this.state.payment_type_5,
       payment_type_6:this.state.payment_type_6,
       payment_type_7:this.state.payment_type_7,
       payment_type_8:this.state.payment_type_8,
       cash_paid:this.state.cash_paid,
       halmark:this.state.halmark,
       dec_purchase_item:this.state.dec_purchase_item,
       payment_note:this.state.payment_note,
       docket_no:this.state.docket_no
    };
    if(validate)
      this.setState({btnSaveLoader:true});
      scrapService.customerQuoteDetailSave(customerParma)
          .then(async (result) => {
              let responseJson = result;  
              if(responseJson.status){
                    let finalData = responseJson.data;  
                     this.props.history.push({ 
                     pathname: '/scrap-quote/order-receipt',
                     state: {
                        scrap_id:this.state.scrap_id, 
                        scrapTotal:this.state.scrapTotal,  
                      }
                    });    
              } 
          })
          .catch(error => {
              console.error(error.data)
               this.setState({btnSaveLoader:false});
          })
     
  } 

  render() {  
    const {configPaymentTypes, configTitle,configDocument } = this.state;  
    
    let configPaymentTypeOptions =configPaymentTypes.length > 0
        && configPaymentTypes.map((item, i) => {
            return (
                <option value={item.key}>{item.value}</option>
            ) 
        }, this);   
    let configTitleOptions = configTitle.length > 0
        && configTitle.map((item, i) => {
            return (
                <option value={item.value}>{item.value}</option>
            ) 
        }, this);   
    let configDocumentOption = configDocument.length > 0
        && configDocument.map((item, i) => {
            return (
                <option value={item.value}>{item.value}</option>
            ) 
        }, this); 
    return (
      <> 
        <div> 
        {this.state.disabled &&
          <button type="button" className="btn btn-secondary mb-5 ml-2" onClick={this.selectCustomerClear}>
            Edit Customer
          </button>
        } 
        </div>  
        <section className="customer-detail-search"> 
        <div className="row">
            <div className="col-sm-12 col-md-12 page-title topcontent">
              <h3 className="text-left">Please collect then save the customer details,including payment data using the form below:</h3>
            </div>         
        </div>
          <div className="row flexcenter m-0"> 
            <div className="col-sm-12 col-md-5">
              <div className="form-group row">
                <label className="col-sm-12 col-md-6">Past Customer Surname</label>
                <input type="text" name="search_surname" className="form-control col-sm-12 col-md-6" value={this.state.customer_search.search_surname} onChange={this.onSearchChange} />
              </div>
            </div>
            <div className="col-sm-12 col-md-5">
              <div className="form-group row">
                <label className="col-sm-12 col-md-6">Past Customer Photo ID</label>
                <input type="text" name="search_photo_id" className="form-control col-sm-12 col-md-6" value={this.state.customer_search.search_photo_id} onChange={this.onSearchChange}/>
              </div>
            </div>
            <div className="col-sm-12 col-md-2 text-right">
              <div className="form-group">
                <button type="button" className="btn btn-sm btn-primary" onClick={this.searchCustomer}>Find Past Customer</button>
              </div>
            </div>  
            <div className="col-sm-12 col-md-5">
              <div className="form-group row">
                <label className="col-sm-12 col-md-6">Past Customer First Name</label>
                <input type="text" name="search_first_name" className="form-control col-sm-12 col-md-6" value={this.state.customer_search.search_first_name} onChange={this.onSearchChange}/>
              </div>
            </div>
            <div className="col-sm-12 col-md-5">
              <div className="form-group row">
                <label className="col-sm-12 col-md-6">Past Customer PostalCode</label>
                <input type="text" name="search_postal_code" onKeyPress={this.onKeyPressEvent} className="form-control col-sm-12 col-md-6" value={this.state.customer_search.search_postal_code} onChange={this.onSearchChange}/>
              </div>
            </div>
            <div className="col-sm-12 col-md-5">
              <div className="form-group row">
                <label className="col-sm-12 col-md-6">Customer Type</label>
                <input type="text" name="search_customer_type" min="0" className="form-control col-sm-12 col-md-6" onChange={this.onSearchChange}/>
              </div>
            </div>
          </div>   
          {(this.state.customers.length > 0) &&  (this.state.selectCustomerList) && 
            <div className="table-responsive p-0">
              <table className="table table-hover text-nowrap">
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Surname</th>
                    <th>Postal</th> 
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>  
                  { this.state.customers.length > 0 ? this.state.customers.map((data, index) => { 
                    return (
                      <tr>
                        <td>{data.first_name}</td>
                        <td>{data.surname}</td>
                        <td>{data.postal_code}</td>
                        <td><button type="button" className="btn btn-sm btn-info" onClick={() => this.selectCustomer(this,index)}>Select</button></td>
                      </tr> 
                    )
                  })
                  :''
                }
                </tbody>
              </table>
            </div>
          }  
        </section>
        <section className="form-block">  
          <div className="row m-0 flexcenter">
          <div className="col-sm-12 col-md-12 page-title mb-2">
              <h5 className="text-left">Customer Billing Name & address</h5>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">Customer Title</label>
                <select name="title" className="form-control col-sm-12 col-md-6" value={this.state.customer.title} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}>
                    <option value="0">Select Title</option>
                    {configTitleOptions}
                  </select>
                <p className="error">{this.state.validationErrors.titleError}</p>
              </div>                
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">First Name</label>
                <input type="text" name="first_name" className="form-control col-sm-12 col-md-6" value={this.state.customer.first_name} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                 <p className="error">{this.state.validationErrors.firstNameError}</p>
              </div>                
              <div className="form-group row">
                  <label className="col-sm-12 col-md-4">Middle Name </label>
                  <input type="text" name="middle_name" className="col-sm-12 col-md-6 form-control"  value={this.state.customer.middle_name} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                  <span className="text-info col-sm-12 col-md-2">(Optional)</span>
               </div>                
              <div className="form-group row">
                  <label className="col-sm-12 col-md-4">Surname</label>
                  <input type="text" name="surname" className="form-control col-sm-12 col-md-6"  value={this.state.customer.surname} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                   <p className="error">{this.state.validationErrors.surnameError}</p>
              </div>
              <div className="form-group row">
                  <label className="col-sm-12 col-md-4">Business Name </label>
                  <input type="text" name="business_name" className="form-control col-sm-12 col-md-6"  value={this.state.customer.business_name} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                  <span className="text-info col-sm-12 col-md-2">(Optional)</span>
              </div>               
              <div className="form-group row">
                  <label className="col-sm-12 col-md-4">Business ABN</label>
                  <input type="text" name="business_abn" className="col-sm-12 col-md-6 form-control"  value={this.state.customer.business_abn} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                  <span className="text-info col-sm-12 col-md-2">(Optional)</span>
              </div>                
              <div className="form-group row">
                  <label className="col-sm-12 col-md-4">Phone Number</label>
                  <input type="text" onKeyPress={this.onKeyPressEvent}  name="phone" className="form-control col-sm-12 col-md-6"  value={this.state.customer.phone}onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                  <span className="text-info col-sm-12 col-md-2">(Optional)</span>
              </div> 
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">Street Address1</label>
                <input type="text" name="street_1" className="form-control col-sm-12 col-md-6"  value={this.state.customer.street_1} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                 <p className="error">{this.state.validationErrors.street_1Error}</p>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">Street Address2</label>
                <input type="text" name="street_2" className="form-control col-sm-12 col-md-6"  value={this.state.customer.street_2} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                <span className="text-info col-sm-12 col-md-2">(Optional)</span>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">Town / Suburb</label>
                <input type="text" name="town" className="form-control col-sm-12 col-md-6"  value={this.state.customer.town} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                 <p className="error">{this.state.validationErrors.townError}</p>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">State / Territory</label>
                <input type="text" name="state" className="form-control col-sm-12 col-md-6"  value={this.state.customer.state} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                <p className="error">{this.state.validationErrors.stateError}</p>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">PostalCode</label>
                <input type="text" name="postal_code" onKeyPress={this.onKeyPressEvent} className="form-control col-sm-12 col-md-6"  value={this.state.customer.postal_code} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                 <p className="error">{this.state.validationErrors.postalCodeError}</p>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">Country</label>
                <input type="text" name="country" className="form-control col-sm-12 col-md-6"  value={this.state.customer.country} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                 <p className="error">{this.state.validationErrors.countryError}</p>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">Date of Sale</label>
                <DatePicker placeholderText="dd-mm-yyyy" dateFormat="dd-MM-yyyy" className="form-control col-sm-12 col-md-12" value={ this.state.sale_date} selected={this.state.sale_date && new Date(this.state.sale_date)} name="sale_date"   onChange={(date) => {
                  this.handleDateChange('sale_date',date); 
                }}  />
                <p className="error">{this.state.validationErrors.saleDateError}</p>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-md-4">Time of Sale</label>
                <input type="text" name="sale_time" className="form-control col-sm-12 col-md-6" value={this.state.sale_time} onChange={this.onInputChange}/>
                <p className="error">{this.state.validationErrors.saleTimeError}</p>
              </div>
            </div>
          </div>  
          <div className="row m-0 flexcenter">
            <div className="col-sm-12 col-md-8 page-title">
              <h5>Customer Photo Identity Documentation</h5>
            </div> 
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4">Document Type</label>
                    <select name="doc_type" className="form-control col-sm-12 col-md-6" value={this.state.customer.doc_type} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}>
                        <option value="0">Select Document</option>
                        {configDocumentOption}
                      </select>
                     <p className="error">{this.state.validationErrors.docTypeError}</p>
                  </div> 
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4">Country of Issue</label>
                    <input type="text" name="country_issue" className="form-control col-sm-12 col-md-6" value={this.state.customer.country_issue} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                     <p className="error">{this.state.validationErrors.countryIssueError}</p>
                  </div> 
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4">ID Number</label>
                    <input type="text" name="id_number" className="form-control col-sm-12 col-md-6" value={this.state.customer.id_number} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                     <p className="error">{this.state.validationErrors.idNumberError}</p>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4">DOB</label>
                    <DatePicker placeholderText="dd-mm-yyyy" dateFormat="dd-MM-yyyy" className="form-control col-sm-12 col-md-12" value={ this.state.customer.dob} selected={this.state.customer.dob && new Date(this.state.customer.dob)} name="dob"   onChange={(date) => {
                      this.handleDOBDateChange('dob',date); 
                    }}  />
                    <p className="error">{this.state.validationErrors.dobError}</p>
                  </div> 
                  <div className="form-group row">
                      <label className="col-sm-12 col-md-4">Expiry Date</label>
                      <DatePicker placeholderText="dd-mm-yyyy" dateFormat="dd-MM-yyyy" className="form-control col-sm-12 col-md-12" value={ this.state.customer.expiry_date} selected={this.state.customer.expiry_date && new Date(this.state.customer.expiry_date)} name="sale_date"   onChange={(date) => {
                      this.handleExpiryDateChange('expiry_date',date); 
                    }}  />
                    <p className="error">{this.state.validationErrors.expiryDateError}</p>
                  </div> 
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4">Text Based ID </label>
                    <input type="text" name="text_based_id" className="form-control col-sm-12 col-md-6" value={this.state.customer.text_based_id} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                    <span className="text-info col-sm-12 col-md-2">(Optional)</span>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-4">Text ID Customer #</label>
                    <input type="text" name="text_customer_id" className="form-control col-sm-12 col-md-7" value={this.state.customer.text_customer_id} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                     <p className="error">{this.state.validationErrors.textCustomerIdError}</p>
                  </div>
                </div> 
              </div> 
            </div> 
          </div> 
          <div className="row m-0 flexcenter">
            <div className="page-title">
              <h5>Customer Sale Items Summary:(Previous Completed)</h5>
            </div> 
            <div className="col-sm-12 col-md-12 p-0">
              <table className="table table-hover text-nowrap"> 
                <thead>
                    <tr>
                        <th scope="col">Metal Type</th>
                        <th scope="col">Purity Value</th>
                        <th scope="col">Total Weight</th>
                        <th scope="col">Payment Rate</th>
                        <th scope="col">Spote Price</th>
                        <th scope="col">Payment</th>   
                    </tr>
                </thead>
                <tbody>
                    { this.state.custom_scraps.length > 0 ? this.state.custom_scraps.map((data, index) => { 
                        return (
                            <tr>
                              <td>{data.metal_name}</td>
                              <td>{data.purity_value}</td>
                              <td>{ data.grams ? data.grams :''}</td>
                              <td>{data.payment_rate}</td>
                              <td>
                                {data.spot_price}
                                <div><span>{data.spot_time}</span></div>
                              </td>
                              <td>
                                <input type="number" className="form_control" value={data.purity_price} readOnly = {data.disabled} readOnly = {this.state.disabled}/> 
                              </td>  
                            </tr>
                        )
                    })
                :
                <tr>
                    <td colSpan={6}>No result found</td>
                </tr>
                }     
                </tbody>
                <tfoot className="price">
                  <tr> 
                    <th scope="col" colSpan="6">Total Customer Payment: ${this.state.scrapTotal}</th>
                  </tr>
                </tfoot>
              </table>
            </div>  
          </div>
          <div className="row m-0 flexcenter">
            <div className="col-sm-12 col-md-10 page-title">
              <h5 >Method of Payment: (This page will reload with more options after your section)</h5>
            </div> 
            <div className="col-sm-12 col-md-12">
             <div className="row"> 
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Payment Type-1:</label> 
                    <select name="payment_type_1" className="form-control col-sm-12 col-md-7" value={this.state.payment_type_1} onChange={this.onInputChange}>
                      <option value="">Select Payment Type</option>
                      {configPaymentTypeOptions}
                    </select>
                  </div> 
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Payment Type-2:</label>
                     <select name="payment_type_2" className="form-control col-sm-12 col-md-7" value={this.state.payment_type_2} onChange={this.onInputChange}>
                      <option value="">Select Payment Type</option>
                      {configPaymentTypeOptions}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Payment Type-3:</label>
                     <select name="payment_type_3" className="form-control col-sm-12 col-md-7" value={this.state.payment_type_3} onChange={this.onInputChange}>
                      <option value="">Select Payment Type</option>
                      {configPaymentTypeOptions}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Cash Paid Total $:</label>
                    <input type="text" name="cash_paid" className="form-control col-sm-12 col-md-7" value={this.state.scrapTotal} onChange={this.onInputChange} disabled/>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Payment Type-4:</label>
                     <select name="payment_type_4" className="form-control col-sm-12 col-md-7" value={this.state.payment_type_4} onChange={this.onInputChange}>
                      <option value="">Select Payment Type</option>
                      {configPaymentTypeOptions}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Payment Type-5:</label>
                     <select name="payment_type_5" className="form-control col-sm-12 col-md-7" value={this.state.payment_type_5} onChange={this.onInputChange}>
                      <option value="">Select Payment Type</option>
                      {configPaymentTypeOptions}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Payment Type-6:</label>
                    <select name="payment_type_6" className="form-control col-sm-12 col-md-7" value={this.state.payment_type_6} onChange={this.onInputChange}>
                      <option value="">Select Payment Type</option>
                      {configPaymentTypeOptions}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Payment Type-7:</label>
                     <select name="payment_type_7" className="form-control col-sm-12 col-md-7" value={this.state.payment_type_7} onChange={this.onInputChange}>
                      <option value="">Select Payment Type</option>
                      {configPaymentTypeOptions}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Payment Type-8:</label>
                     <select name="payment_type_8" className="form-control col-sm-12 col-md-7" value={this.state.payment_type_8} onChange={this.onInputChange}>
                      <option value="">Select Payment Type</option>
                      {configPaymentTypeOptions}
                    </select>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-5">Receipt Docket #:</label>
                    <input type="text" name="docket_no" className="form-control col-sm-12 col-md-7" onKeyPress={this.onKeyPressEvent} value={this.state.docket_no} onChange={this.onInputChange} />
                     <p className="error">{this.state.validationErrors.docketNoError}</p>
                  </div>
                </div>
             </div>
            </div>  
          </div>
          
              <div className="row m-0 flexcenter">
                <div className="col-sm-12 col-md-12">
                  <div className="form-group row">  
                     <h5>Description of Purchase Items(WILL APPEAR ON CUSTOMER RECEIPT & TRANSACTION REGISTER)</h5>
                    <textarea name="dec_purchase_item" className="form-control" value={this.state.dec_purchase_item} onChange={this.onInputChange}></textarea>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group row">  
                    <label className="col-sm-12 col-md-4">Item(s) Hallmark(s) / Identifiable  Features</label>
                    <input type="text" name="halmark" className="form-control col-sm-12 col-md-6" value={this.state.text_based_id} onChange={this.onInputChange}/>
                  </div>
                </div>
              </div> 
              
              
              <div className="row m-0 flexcenter">
                <div className="col-sm-12 col-md-12">
                  <div className="form-group row">  
                     <h5>Notes Regarding This Payment(Will not appear on customer receipt but will be sent to management)</h5>
                    <textarea name="payment_note" className="form-control" value={this.state.payment_note} onChange={this.onInputChange}></textarea>
                  </div>
                </div>
              </div> 
              
              
              <div className="row m-0 flexcenter">
                <div className="col-sm-12 col-md-12 ">
                  <h5>Finalis this order: <span>(Please ensure all details above are correct before proceeding)</span></h5>
                </div>
                <div className="col-sm-12 col-md-4 ">
                      <button
                        type="button"
                        className="btn btn-sm btn-success"
                        onClick={this.onSaveCustomerOrder}>
                        <div className="buttonloader">
                          <div className={`loader_wrap ${this.state.btnSaveLoader ? 'loader_active' : ''}`}>
                            <div className="preload"></div> </div></div>
                          Cancel & Remove  This Order
                      </button> 
                </div>
                <div className="col-sm-12 col-md-4 ">
                      <button
                        type="button"
                        className="btn btn-sm btn-success scvr"
                        onClick={this.onSaveCustomerOrder}>
                        <div className="buttonloader ">
                          <div className={`loader_wrap ${this.state.btnSaveLoader ? 'loader_active' : ''}`}>
                            <div className="preload"></div> </div></div>
                          Save Customer & View Receipt
                      </button> 
                </div>
              </div> 
                
        </section> 
      </>
    );
  }
}
