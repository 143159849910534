import React, { Component } from "react";
import { Link } from 'react-router-dom'; 
export default class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
      <div className="wrp">
        <strong>Copyright &copy; 2014- {new Date().getFullYear()} <Link to="/"> Gold Secure</Link>. </strong>
        All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> 3.2.0-rc
        </div>
        </div>
      </footer>
    );
  }
}
