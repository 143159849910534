import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { scrapService,configService } from '../../_services'; 
import BtnLoader from '../../component/Btnloader';  
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default class Create extends Component {
  
  constructor(props) {
    super(props); 
      this.state = { 
          'quote_type' : 1,
          'metal_type' : 1,
          'purity_type' : 1,
          'scrap_type' : 1,
          'carat':0,
          'non_metal_carat':0,
          'spot_gold_price':0,
          'custom':'',
          'percent':'',
          'gram':'',
          'qty_gram':'',
          'weight':'',
          'payment':200,
          'payment_rate':200,
          'spot_price':200,
          'qty':'',
          'product':'',
          'metal_item':'',
          'non_metal_gram':'',
          'gold_type':'',
          'non_metal_amount':'',
          'custom_scraps':[
            /*{
              grams: "22",
              metal_name: "Gold",
              metal_type: 1,
              parma: {quote_type: 1, metal_type: 1, purity_type: 1, scrap_type: 1, carat: "15.0", gram: "22"}, 
              payment_rate: "62.50",
              purity_price: "1068.48",
              purity_value: "15.0",
              quote_type: 1,
              spot_price: "77.71",
              spot_time: "04:05:00",
              disabled:true,
              status: true,
            }, */
          ],
          'scrapTotal':'00.00',
          'validationErrors':{
            caratError: '', 
            gramError: '', 
            metalAmountError:'',
            goldTypeError:'',
            metalItemError:'',
            customError:'',
            percentError:'',
            qtyError:'',
            productError:'',
          },
          'configMetals' : [],
          'configCarats' : [],
          'configPercentages' : [],
          'configScrapType' : [],
          'configProducts' : [],
          token:'',
          btnLoader:false,
          btnSaveLoader:false,
          indexUpdate:false,
          indexEdit:true,
      }  
    };  
  async componentDidMount(){
      let checkTokenData = localStorage.getItem("userToken");
      if (checkTokenData) {
        let checkToken = JSON.parse(checkTokenData); 
        let accessToken = checkToken.access_token;
        this.setState({
            name: checkToken.name,
            role: checkToken.role,
            token: accessToken
        });
        configService.getConfig('metal_type=true&carat=true&percentage=true&scrap_type=true&products=true')
        .then(async (result) => {
            let responseJson = result;  
            if(responseJson.status){
                  let finalData = responseJson.data;  
                  this.setState({
                        configMetals:finalData.metal_type,
                        configCarats:finalData.carat,
                        configPercentages:finalData.percentage,
                        configScrapType:finalData.scrap_type,
                        configProducts:finalData.products,
                  }); 
            } 
        });
      }
  }
  onChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
      this.setState({ [name]: value }); 
  } 
  onCreateOrder = (e) => {
    e.preventDefault();
      const isValid = this.validate(); 
      const {token,custom_scraps} = this.state; 
      const custom_scraps_parma = {custom_scraps:custom_scraps}; 
      let checkTokenData = localStorage.getItem("userToken");
      if(checkTokenData){  
       this.setState({btnSaveLoader:true});
       scrapService.createQuote(custom_scraps_parma)
       .then(response => { 
          if (response.data.status) { 
              this.props.history.push({ 
               pathname: '/scrap-quote/customer-details',
               state: {
                  scrap_id:response.data.scrap_id,
                  custom_scraps:this.state.custom_scraps,
                  scrapTotal:this.state.scrapTotal, 
                }
              }); 
              this.setState({btnSaveLoader:false}); 
          }
      })
      .catch(error => {
          console.error(error.data)
           this.setState({btnSaveLoader:false});
      }) 
      } 
  }
  validate = () => {
    let validateStatus = true;
      const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
       const onlyNumberRegExp = /^[0-9\b]+$/;
      if(this.state.quote_type == '1' || this.state.quote_type == '3'){
          if (this.state.gram.length < 1) { 
              this.setState(Object.assign(this.state.validationErrors,{gramError:'Please Enter gram'}));
              validateStatus = false;
          }else if (!floatRegExp.test(this.state.gram)){
              this.setState(Object.assign(this.state.validationErrors,{gramError:'Please Enter Valid Gram'}));
              validateStatus = false;
          }else if (this.state.gram <= 0){
              this.setState(Object.assign(this.state.validationErrors,{gramError:'Gram Must be Greater Than 0'}));
              validateStatus = false;
          }else{
              this.setState(Object.assign(this.state.validationErrors,{gramError:''}));
          }
      }
      if (this.state.quote_type == '1') {  
          if(this.state.purity_type == '1'){
            if (this.state.carat == 0) {  
                this.setState(Object.assign(this.state.validationErrors,{caratError:'Please select carat'}));
                validateStatus = false;
            }else{ 
                this.setState(Object.assign(this.state.validationErrors,{caratError:''}));
            }
          }else if(this.state.purity_type == '2'){
            if (this.state.percent == 0) {  
                this.setState(Object.assign(this.state.validationErrors,{percentError:'Please select percent'}));
                validateStatus = false;
            }else{ 
                this.setState(Object.assign(this.state.validationErrors,{percentError:''}));
            }
          }else{
            if (this.state.custom == 0) {  
                this.setState(Object.assign(this.state.validationErrors,{customError:'Please enter custom'}));
                validateStatus = false;
            }else{ 
                this.setState(Object.assign(this.state.validationErrors,{customError:''}));
            }
          }
      }else if(this.state.quote_type == '2'){ 
          if (this.state.qty.length < 1) { 
              this.setState(Object.assign(this.state.validationErrors,{qtyError:'Please Enter Quantity'}));
              validateStatus = false;
          }else if (!onlyNumberRegExp.test(this.state.qty)){
              this.setState(Object.assign(this.state.validationErrors,{qtyError:'Please Enter Valid Quantity'}));
              validateStatus = false;
          }else if (this.state.qty <= 0){
              this.setState(Object.assign(this.state.validationErrors,{qtyError:'Quantity Must be Greater Than 0'}));
              validateStatus = false;
          }else{
              this.setState(Object.assign(this.state.validationErrors,{qtyError:''}));
          }
           if (this.state.product == 0) {  
                this.setState(Object.assign(this.state.validationErrors,{productError:'Please select Product'}));
                validateStatus = false;
            }else{ 
                this.setState(Object.assign(this.state.validationErrors,{productError:''}));
            }

      } 
       
    return validateStatus;
  }
  validate2 = () => {
    let validateStatus = true;
      const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
       const onlyNumberRegExp = /^[0-9\b]+$/;
      if (this.state.qty_gram.length < 1) { 
          this.setState(Object.assign(this.state.validationErrors,{qtyGramError:'Please Enter Quantity'}));
          validateStatus = false;
      }else if (!onlyNumberRegExp.test(this.state.qty_gram)){
          this.setState(Object.assign(this.state.validationErrors,{qtyGramError:'Please Enter Valid Quantity'}));
          validateStatus = false;
      }else if (this.state.qty_gram <= 0){
          this.setState(Object.assign(this.state.validationErrors,{qtyGramError:'Quantity Must be Greater Than 0'}));
          validateStatus = false;
      }else{
          this.setState(Object.assign(this.state.validationErrors,{qtyGramError:''}));
      }
       if (this.state.product == 0) {  
            this.setState(Object.assign(this.state.validationErrors,{productError:'Please select Product'}));
            validateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{productError:''}));
        }
       
    return validateStatus;
  }
  validate3 = () => {
    let validateStatus = true;
      const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
       const onlyNumberRegExp = /^[0-9\b]+$/;
      if (this.state.non_metal_amount.length < 1) { 
        this.setState(Object.assign(this.state.validationErrors,{metalAmountError:'Please Enter Value'}));
        validateStatus = false;
      }else if (!floatRegExp.test(this.state.non_metal_amount)){
        this.setState(Object.assign(this.state.validationErrors,{metalAmountError:'Please Enter Valid Value'}));
        validateStatus = false;
      }else if (this.state.non_metal_amount <= 0){
        this.setState(Object.assign(this.state.validationErrors,{metalAmountError:'Value Must be Greater Than 0'}));
        validateStatus = false;
      }else{
        this.setState(Object.assign(this.state.validationErrors,{metalAmountError:''}));
      }

    if (this.state.non_metal_carat == 0) {  
        this.setState(Object.assign(this.state.validationErrors,{NoncaratError:'Please select Gold'}));
        validateStatus = false;
    }else{ 
        this.setState(Object.assign(this.state.validationErrors,{NoncaratError:''}));
    }
    if (this.state.metal_item.length < 1) { 
        this.setState(Object.assign(this.state.validationErrors,{metalItemError:'Please Enter Non Metal Name'}));
        validateStatus = false;
    }else{ 
        this.setState(Object.assign(this.state.validationErrors,{metalItemError:''}));
    } 
    if (this.state.weight.length < 1) { 
        this.setState(Object.assign(this.state.validationErrors,{weightItemError:'Please Enter Weight'}));
        validateStatus = false;
    }else{ 
        this.setState(Object.assign(this.state.validationErrors,{weightItemError:''}));
    } 
    if (this.state.non_metal_carat.length < 1) { 
        this.setState(Object.assign(this.state.validationErrors,{nonGramItemError:'Please Select Gram'}));
        validateStatus = false;
    }else{ 
        this.setState(Object.assign(this.state.validationErrors,{nonGramItemError:''}));
    } 
       
    return validateStatus;
  }
  quoteCreate = (e) => { 
     const isValid = this.validate(); 
      const {metal_type,purity_type,scrap_type,carat,gram,percent,custom,spot_gold_price } = this.state;
      let custom_scraps = this.state.custom_scraps; 
      let customScrap = {}; 
      customScrap = {
          'quote_type' : 1,
          'metal_type' : metal_type,
          'purity_type' : purity_type,
          'scrap_type' : scrap_type,
          'carat':carat,
          'gram':gram, 
          'percent':percent, 
          'custom':custom, 
          'spot_gold_price':spot_gold_price, 
      } 
      this.setState({btnLoader:true});
      if(isValid){ 
        scrapService.calculateQuote(customScrap)
          .then(async (result) => {
              let responseJson = result;  
              if(responseJson.status){
                let finalData = responseJson.data;  
                 this.setState({ 
                    'quote_type' : 1,
                    'metal_type' : 1,
                    'purity_type' : 1,
                    'scrap_type' : 1,
                    'carat':'',
                    'gram':'', 
                    'spot_gold_price':'', 
                    btnLoader:false,
                    indexEdit:true,
                    custom_scraps: [...this.state.custom_scraps, finalData]
                  },this.calculateScrapTotal); 
              } 
          }); 
      }else{
        this.setState({btnLoader:false});
      }
  } 
  productQuoteCreate = (e) => { 
     const isValid = this.validate2(); 
      const {qty_gram,product,spot_gold_price } = this.state;
      let custom_scraps = this.state.custom_scraps; 
      let customScrap = {}; 
      customScrap = {
        'quote_type' : '2', 
        'qty':qty_gram,
        'product':product, 
        'spot_gold_price':spot_gold_price, 
      }
      this.setState({btnLoader:true});
      if(isValid){ 
        scrapService.calculateQuote(customScrap)
          .then(async (result) => {
              let responseJson = result;  
              if(responseJson.status){
                let finalData = responseJson.data;  
                 this.setState({ 
                    'quote_type' : 2, 
                    'qty_gram':'',
                    'qty_gram':'', 
                    'spot_gold_price':'', 
                    btnLoader:false,
                    indexEdit:true,
                    custom_scraps: [...this.state.custom_scraps, finalData]
                  },this.calculateScrapTotal); 
              } 
          }); 
      }else{
        this.setState({btnLoader:false});
      }
  } 
  NonMetalquoteCreate = (e) => { 
     const isValid = this.validate3(); console.log(isValid);
      const {metal_item, non_metal_carat, non_metal_amount, gold_type, weight} = this.state;
      let custom_scraps = this.state.custom_scraps; 
      let customScrap = {}; 
      customScrap = {
          'quote_type' : 3, 
          'metal_item':metal_item,
          'carat':non_metal_carat,
          'gold_type':gold_type,
          'non_metal_amount':non_metal_amount,
          'gram':weight,
      }
      this.setState({btnLoader:true});
      if(isValid){ 
        scrapService.calculateQuote(customScrap)
          .then(async (result) => {
              let responseJson = result;  
              if(responseJson.status){ 
                let finalData = responseJson.data;  
                 this.setState({ 
                    'quote_type' : 3,
                    'metal_item':'',
                    'non_metal_amount':'', 
                    'weight':'', 
                    btnLoader:false,
                    indexEdit:true,
                    custom_scraps: [...this.state.custom_scraps, finalData]
                  },this.calculateScrapTotal); 
              } 
          }); 
      }else{
        this.setState({btnLoader:false});
      }
  } 
  onEdit = (e,x) => { 
    this.setState({indexUpdate:true,indexEdit:false});
    this.state.custom_scraps[x].disabled = false;
    this.forceUpdate(); 
  } 
  onEditChnage(i, event) {
     let values = [...this.state.custom_scraps];
     values[i].purity_price = event.target.value;
     this.setState({ values });
     this.calculateScrapTotal();
  } 
  onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(new RegExp("[0-9.]").test(keyValue)))
      event.preventDefault();
    return;
  }
  onUpdate = (i,event) => {
    let values = [...this.state.custom_scraps]; 
    this.setState({ values });
    this.state.custom_scraps[event].disabled = true;
    this.setState({indexUpdate:false,indexEdit:true});
  }
  onDelete = (e,index) => {
    var array = [...this.state.custom_scraps]; // make a separate copy of the array 
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({custom_scraps: array},this.calculateScrapTotal); 
    } 
  }

  calculateScrapTotal(){
    let custom_scraps = [...this.state.custom_scraps];
    const scrapTotal = this.state.scrapTotal;
    //let total = custom_scraps.reduce((a,v) =>  a = a + v.purity_price , 0 )
    let totals =  custom_scraps.reduce((total, obj) => {
      if (typeof obj['purity_price'] === 'string') {
        return total + Number(obj['purity_price']);
      }
      return total + obj['purity_price'];
    }, 0);
    this.setState({scrapTotal:totals.toFixed(2)});
  }


  render() { 
    const {configMetals,configCarats,configPercentages,configProducts} = this.state; 
    let configMetalsOptions =configMetals.length > 0
        && configMetals.map((item, i) => {
            return (
                <option value={item.key}>{item.value}</option>
            ) 
        }, this); 
    let configCaratsOptions =configCarats.length > 0
        && configCarats.map((item, i) => {
            return (
                <option value={item.key}>{item.value}</option>
            ) 
        }, this); 
    let configPercentagesOptions =configPercentages.length > 0
        && configPercentages.map((item, i) => {
            return (
                <option value={item.key}>{item.value}</option>
            ) 
        }, this);

    let configProductOptions =configProducts.length > 0
        && configProducts.map((item, i) => {
            return (
                <option value={item.key}>{item.value}</option>
            ) 
        }, this);
      
    return (
      <>
       <section className="content-header">
           <ToastContainer />  
          <div className="container-fluid">
            <div className="row topcontent">
              <div>
                <h1>Generate Customer Scrap Gold / Sliver Quote</h1>
              </div>
              <div>
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/scrap-quote">Scrap Quotes</Link></li>
                  <li className="breadcrumb-item active">Create</li>
                </ol>
              </div>
            </div> 
          </div>
        </section>
        <section className="form-block">
        <div className="col-sm-12 col-md-12">
              <div className="scrap-list-wrap">
                <div className="table-responsive p-0">
                  <table className="table table-hover  createtable">
                    <thead className="text-nowrap">
                      <tr>
                        <th scope="col">Metal Type</th>
                        <th scope="col">Purity Value</th>
                        <th scope="col">Total Weight</th>
                        <th scope="col">Payment Rate</th>
                        <th scope="col">Spote Price</th>
                        <th scope="col">Payment</th> 
                        <th scope="col">Action</th> 
                      </tr>
                    </thead>
                    <tbody>
                      { this.state.custom_scraps.length > 0 ? this.state.custom_scraps.map((data, index) => { 
                        return (
                          <tr>
                            <td>{data.metal_name}</td>
                            <td>{data.purity_value}</td>
                            <td>{ data.grams ? data.grams :''} grams</td>
                            <td>{data.payment_rate}% of spot</td>
                            <td>
                              {data.spot_price} per ozt
                              <div>@ <span>{data.spot_time}</span></div>
                            </td>
                            <td>
                              <div className="input-group mb-3 ratesbox">
                                <div className="input-group-append">
                                  <span className="input-group-text">$</span>
                                </div>
                                <input type="text" min="0" className="form-control" name="gram" value={data.purity_price} disabled = {data.disabled} onChange={this.onEditChnage.bind(this, index)} onKeyPress={this.onKeyPressEvent}/>
                              </div> 
                            </td> 
                            <td className="editbutton">
                              { this.state.indexEdit &&
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => this.onEdit(this,index)} >Edit</button>
                              }
                              { this.state.indexUpdate &&
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => this.onUpdate(this,index)} >Update</button>                                                                                                                                                                                                         
                              }
                              <button type="button" className="btn btn-sm btn-danger" onClick={() => this.onDelete(this,index)}>Delete</button>
                            </td> 
                          </tr>
                        )
                      })
                    :
                    ''
                    }    
                    <tr>
                      <td>
                        <select className="form-control w-auto" name="metal_type" onChange={this.onChange}> 
                          {configMetalsOptions}
                        </select>
                      </td>
                      <td className="divmargin">
                        <div className="row">
                          <div className=" col-sm-12 col-md-4"><label className="form-label">Carat</label></div>
                          <div className=" col-sm-12 col-md-8">
                            <select className="form-control" value={this.state.carat} name="carat" onChange={this.onChange}>
                              <option value="0">Select Carat</option>
                              {configCaratsOptions}
                            </select>
                            <p className="error">{this.state.validationErrors.caratError}</p>
                          </div>
                        </div> 
                        <div className="row">
                          <div className=" col-sm-12 col-md-4"><label className="form-label">Percent</label></div>
                          <div className=" col-sm-12 col-md-8">
                            <select className="form-control" name="percent" value={this.state.percent} onChange={this.onChange}>
                              <option value="0">*Exclude Percentage*</option>
                              {configPercentagesOptions}
                            </select> 
                             <p className="error">{this.state.validationErrors.percentError}</p> 
                          </div>
                        </div> 
                        <div className="row">
                          <div className=" col-sm-12 col-md-4"><label className="form-label">Percent</label></div>
                          <div className=" col-sm-12 col-md-8">
                            <input type="text" className="form-control  col-sm-12" name="custom"  onChange={this.onChange}/> 
                             <p className="error">{this.state.validationErrors.customError}</p>
                          </div>
                        </div> 
                      </td>
                      <td>
                        <div className="row">
                          <div className=" col-sm-12 col-md-8 ">
                            <input type="text" min="0" min="0" className="form-control" name="gram" value={this.state.gram} onChange={this.onChange} onKeyPress={this.onKeyPressEvent}/>
                           <p className="error">{this.state.validationErrors.gramError}</p>
                          </div>
                         <div className=" col-sm-12 col-md-4 dcenter"><label className="form-label custom-gram">Gram</label></div>
                        </div>
                      </td>
                      <td>
                        <select className="form-control" name="scrap_type" onChange={this.onChange}> 
                        <option value="1">Scrap Metal</option>
                        <option value="2">Gold / Silver Coins</option>
                        <option value="3">Damaged Bullion</option>
                      </select>  
                      </td>
                      <td colSpan="3">
                        <button type="button" className="btn btn-sm btn-success" onClick={this.quoteCreate}> <btnLoader btnLaoder={this.state.btnLoader}/> { this.state.quote_type == 3 ? 'Add' : 'Generate Payment Rate'}</button>
                      </td> 
                    </tr> 
                    <tr className="grayrow">
                      <td><h4 className="title">Specific Product:</h4></td>
                      <td><select className="form-control" name="product" value={this.state.product} onChange={this.onChange}>
                        <option value="0">Select Product</option>
                        {configProductOptions}
                      </select>
                       <p className="error">{this.state.validationErrors.productError}</p></td>
                      <td><div className="input-group mb-3 pt-3">
                      <label>Quantity </label>
                      <input type="text" min="0" min="0" className="form-control" name="qty_gram" value={this.state.qty_gram} onChange={this.onChange} onKeyPress={this.onKeyPressEvent}/>
                    </div>
                     <p className="error">{this.state.validationErrors.qtyGramError}</p></td>
                      <td colSpan="4"><button type="button" className="btn btn-sm btn-success" onClick={this.productQuoteCreate}> <btnLoader btnLaoder={this.state.btnLoader}/> { this.state.quote_type == 3 ? 'Add' : 'Generate Product Payment Rate'}</button></td>
                  </tr>
                  <tr className="dgrayrow">
                      <td>
                        <h4 className="title">Non Metal Item:</h4>
                      </td>
                      <td>
                        <div className="row">
                          <div className="col-sm-12 col-md-8">
                            <input type="text" className="form-control" name="metal_item"  onChange={this.onChange}/> 
                            <p className="error">{this.state.validationErrors.metalItemError}</p>
                          </div>
                          <div className="col-sm-12 col-md-4"><label>Weight</label></div>
                        </div> 
                      </td>
                      <td>
                        <div className="row">
                          <div className="col-sm-12 col-md-8">
                            <input type="text" min="0" className="form-control" name="weight" value={this.state.weight} onChange={this.onChange} onKeyPress={this.onKeyPressEvent}/>
                            <p className="error">{this.state.validationErrors.weightItemError}</p>
                          </div>
                          <div className="col-sm-12 col-md-4"><label>g</label></div>
                        </div> 
                      </td>
                      <td>
                        <div className="row">
                          <div className=" col-sm-12 col-md-4"><label className="form-label">Gold</label></div>
                          <div className=" col-sm-12 col-md-8">
                            <select className="form-control" value={this.state.non_metal_carat  } name="non_metal_carat" onChange={this.onChange}>
                              <option value="0">* No Gold Contents *</option>
                              {configCaratsOptions}
                            </select>
                            <p className="error">{this.state.validationErrors.NoncaratError}</p>
                          </div> 
                        </div>
                      </td> 
                      <td>
                        <div className="row">
                          <div className=" col-sm-12 col-md-4"><label className="form-label">Value</label></div>
                          <div className=" col-sm-12 col-md-8 dnflex">
                            <span> $</span><input type="text" min="0" className="form-control" name="non_metal_amount" value={this.state.non_metal_amount} onChange={this.onChange} onKeyPress={this.onKeyPressEvent}/>
                          </div> 
                        </div> 
                          <p className="error">{this.state.validationErrors.nonGramItemError}</p>
                      </td> 
                      <td colSpan="2">
                        <button type="button" className="btn btn-sm btn-success w-100" onClick={this.NonMetalquoteCreate}> <btnLoader btnLaoder={this.state.btnLoader}/>Add Item</button>
                      </td> 
                  </tr>
                    </tbody>
                  </table>
                    <div className="totalbox">
                        <div>Total Payment</div>
                        <div>${this.state.scrapTotal} AUD</div> 
                        <div>
                          { this.state.scrapTotal > 0 &&
                          <button type="button" className="btn btn-sm btn-success" onClick={this.onCreateOrder}><BtnLoader btnLaoder={this.state.btnSaveLoader}/> Process With Order</button> 
                          }
                        </div> 
                    </div>
                </div> 
              </div>
            </div>
            
        </section> 
      </>
    );
  }
}
