import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { scrapService,configService,customerService } from '../../_services';  
import PrintReceipt from '../../component/PrintReceipt';  
import PrintCustomerReceipt from '../../component/PrintCustomerReceipt';  
import axios from "axios";
import PrintComponents from "react-print-components";
export default class CustomerDetails extends Component { 
  constructor(props) {
    super(props); 
      this.state = {
           scrap_id:'', 
           custom_scraps:[],
           scrapTotal:'', 
           owner_detail:'',
           trasactionDetail:'',
           trasaction_detail:'',
           goldItems:[],
           goldDetails:[],
           ownerDetail:[],
      }  
    };  
  async componentDidMount(){
     let checkTokenData = localStorage.getItem("userToken"); 
        if (this.props.location.state) { 
            const scrapId =  this.props.location.state.scrap_id;
              this.setState({
                scrap_id:this.props.location.state.scrap_id,
                custom_scraps:this.props.location.state.custom_scraps, 
              }); 
              scrapService.getOrderRecept(scrapId)
              .then(async (result) => {
                  let responseJson = result;  
                   
                  if(responseJson.status){
                  console.log(responseJson.data); 
                        let finalData = responseJson.data.data; 
                        
                        this.setState({
                          owner_detail:finalData.owner_detail,
                          trasaction_detail:finalData.trasaction_detail,
                          custom_scraps:finalData.custom_scraps,
                          ownerDetail:finalData.owner_detail,
                          trasactionDetail:finalData.trasaction_detail, 
                          goldItems:finalData.custom_scrap.gold.items, 
                          goldDetails:finalData.custom_scrap.gold, 
                        }); 
                  } 
                  
              });
        }else{
          this.props.history.push(`/scrap-quote`);
        }  
  }  
  render() { 
    return (
      <>
         <div className="row m-0 flexcenter flexcenterreceipt">
            <div className="col-sm-12 col-md-8 receipt-btns">
              <h3 className="title">Your Customer Order Has Been Saved</h3>
            </div>
            <div className="col-sm-12 col-md-4 receipt-btns">
                <div className="text-right">
                  <Link to={{pathname: "/scrap-quote/customer-receipt", state: {scrap_id: this.state.scrap_id}}} className="btn btn-md btn-success">Print Customer Receipt</Link>
                  <br/>
                  <Link to={{pathname: "/scrap-quote/transication-receipt", state: {scrap_id: this.state.scrap_id}}} className="btn btn-md btn-success mt-2">Print Register Receipt</Link>
                </div>     
            </div>
         	<div className="col-sm-12 col-md-12">
            
            <div className="receipt-detail-wrap mt-3">
              <div className="row">
                <div className="col-sm-12 col-md-6"> 
                    <p className="customer-title">Customer Details:</p>
                    <p className="m-0 ml-2">{this.state.owner_detail.cust_name}</p>
                    <p className="m-0 ml-2">{this.state.owner_detail.street_1}</p>
                    <p className="m-0 ml-2">{this.state.owner_detail.town} {this.state.owner_detail.state} {this.state.owner_detail.postal_code}</p>
                </div>
                <div className="col-sm-12 col-md-6 text-right">
                  <div className="receipt-detail">
                     <p className="m-0"><strong>Docket #:</strong> { this.state.trasactionDetail.docket_no }</p>
                     <p className="m-0"><strong>Date:</strong> { this.state.trasactionDetail.date }</p>
                     <br/>
                     <p className="m-0 price"><strong>$</strong> { this.state.trasactionDetail.amount } AUD</p>
                  </div>
                </div>
              </div>
            </div> 
         	</div>

         </div>  
      
      </>
    );
  }
}
