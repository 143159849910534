import axios from "axios"; 
import { authHeader } from '../_helpers';
export const settingService = {
    getSettings,
    settingUpdate,
    calculatePercentage  
}; 
 

function getSettings() {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.get(`${window.$URL}gold-sliver-carat-list`, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function settingUpdate(parma) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.post(`${window.$URL}gold-sliver-price-update`, parma, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
 
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text); 
        return data;
    });
}
function calculatePercentage(quoteParma) {
     const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.post(`${window.$URL}calculate-gold-sliver-percentage`, quoteParma, requestOptions)
        .then((response) => response)
        .catch((error) => { 
        });
}