import React, { Component } from "react"; 
import { Link } from 'react-router-dom';
import axios from "axios";
import { authService} from '../../_services'; 

const initialState = {
  email: "",
  password: "",
  loading: false,  
  validationErrors :{ 
    emailError: '', 
    passwordError: '',
},
  Isloggedin: false,
  success: false,
  successMessage: "",
  errror: false,
  errorMessage: "",
  loginBtnLoader:false
};

export default class Login extends Component {
  state = initialState;

  onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState({ [name]: value })
  };

  validate = () => {
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        let emailError = '';
        let passwordError = '';

        let formvalidateStatus = true;  

        if (this.state.email.length < 1) {
            emailError = "Please enter Email";
        } else if (!regex.test(this.state.email.trim())) {
            emailError = "Please enter a valid Email"
        }      
        if (emailError) { 
            this.setState(Object.assign(this.state.validationErrors,{emailError:emailError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{emailError:''}));
        }
          
        if (this.state.password.length < 1) {
            passwordError = "Please enter Password";
        }
        if (passwordError) {
            this.setState(Object.assign(this.state.validationErrors,{passwordError:passwordError}));
            formvalidateStatus = false;
        }else{
            this.setState(Object.assign(this.state.validationErrors,{passwordError:''}));
        } 
        return formvalidateStatus
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({loginBtnLoader:true});
    const { email, password } = this.state;
    const isValid = this.validate(); 
    if (isValid) { 
      axios
        .post(`${window.$URL}login`, { email, password })
        .then((response) => {  
          if (response.data.success === true) { 
            var userTokenData ={
              name:response.data.data.name,
              role:response.data.data.role,
              access_token:response.data.data.access_token,
              cm_id :response.data.data.cm_id 
              };
            localStorage.setItem("userToken",JSON.stringify(userTokenData));
             
              this.props.history.push(`/staff-panel`); 
          } else {
            this.setState({
              Isloggedin: false,
              errror: true,
              errorMessage: response.data.data.message,
              loginBtnLoader:false
            });
          }
          
        })
        .catch((error) => {
          this.setState({
            errror: true,
            errorMessage: 'Incorrect User Name And Password',
            loginBtnLoader:false
          });
        });
    }else{
      this.setState({ 
        loginBtnLoader:false
      });
    }
  };

  render() {
    return (
      <div className="login-box"> 
        <div className="card card-outline card-primary">
          <div className="card-header text-center bg-primary"> 
             <img src={require('../../assets/images/logo.png')} alt="AdminLTE Logo" className="brand-image " />
          </div>
          <div className="card-body">
            <p className="login-box-msg">Sign in to start your session</p> 
            {
               this.state.errror
                 ?
                 <>
                       <p style={{ color: "red" }}>{this.state.errorMessage}.</p>
                 </>
                 :
               ""
            }
            {
              this.state.success
                   ?
                   <>
                       <p style={{ color: "green" }}>{this.state.successMessage}.</p>
              </>
              :
            ""
            }
            <form onSubmit={this.onSubmit} autocomplete="on">
              <div className="input-group">
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope"></span>
                  </div>
                </div>
                <input type="email" name="email" className="form-control" placeholder="Email" onChange={this.onChange}
                  />  
              </div> 
              <p className="error-login text-red">{this.state.validationErrors.emailError}</p>
              <div className="input-group">
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
                <input type="password" name="password" className="form-control" placeholder="Password" onChange={this.onChange}/>
              </div>  
              <p className="error-login text-red">{this.state.validationErrors.passwordError}</p>
              <div className="center">
                <button type="submit" className="btn btn-primary btn-block">
                  <div className="buttonloader"><div className={`loader_wrap ${this.state.loginBtnLoader ? 'loader_active' : ''}`}><div className="preload"></div> </div></div>
                  Sign In
                </button>
              </div>
              <p className="mt-1">
                 <Link to="/forgot-password">Forgot your Password?</Link>  
              </p> 
            </form>
          </div> 
        </div> 
      </div>
    );
  }
}
