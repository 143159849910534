import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios"; 
import { scrapService,configService } from '../../_services'; 
import { ToastContainer, toast } from 'react-toastify'; 
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default class MelboumeStatus extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filterScrap: [],
      scrapeChecked:[],
      scrapIds:[],
      checkedAll:false,
      process_date:'',
      status_type:'',
      isPrevioue:true,
    }; 
  }
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    if (checkTokenData) {
      let checkToken = JSON.parse(checkTokenData);
      //
      let accessToken = checkToken.access_token;

      this.setState({
        name: checkToken.name,
        role: checkToken.role,
        token: accessToken
      })  
        scrapService.getScrapList("1")
	      .then(async (result) => {
	          let response = result;  
	           
	          if(response.status){ 
	              	if (response.data.status) {
			            let totalCount = response.data.data.length; 
			            this.setState({
			              Details: response.data.data,
			              filterScrap: response.data.data,
			              pagelaoder: false
			            });
			            if (totalCount === 0) {
			              toast.error("No result found", {
			                position: "bottom-center",
			                autoClose: 3000,
			                hideProgressBar: false,
			                closeOnClick: true,
			                pauseOnHover: true,
			                draggable: true,
			                progress: undefined,
			              });
			            }
			         }
	          } 
	          
	      }); 
    }
  }

  handlePageChange(pageNumber) {
    const { Details } = this.state;
    const filterScrap = Details;
    this.setState({ filterScrap });
  }
   
  render() { 
    const { filterScrap } = this.state; 
         
    return (
      <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                   Melboume Status
                 </h1>  
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">DataTables</li>
                </ol>
              </div>
            </div>
          </div>
        </section> 
        <div className="row">
          <div className="col-12">
            <div className="card"> 
              <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Docket No</th>
                      <th>Customer</th>
                      <th>Date / Time</th>
                      <th>Payment</th>
                      <th>Staff</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {filterScrap.length > 0 ? filterScrap.map((data, index) => {
                      return (
                        <tr> 
                          <td>{data.order_id}</td>
                          <td>{data.docket_no}</td>
                          <td>{data.customer_name}</td>
                          <td>{data.sale_date_time}</td>
                          <td>{data.payment}</td>
                          <td>{data.staff}</td> 
                        </tr>
                      )
                    })
                      :
                      <tr>
                        <td colSpan={8}>No result found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
