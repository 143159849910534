import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { scrapService,configService,customerService } from '../../_services';   
import MelboumePreviewReceipt from '../../component/MelboumePreviewReceipt';  
import axios from "axios";
import PrintComponents from "react-print-components";

export default class MelboumePreview extends Component { 
 constructor(props) {
    super(props); 
      this.state = {
           scrap:'',
           metal_job_gold:[],
           metal_job_sliver:[],
           putity_gold_total:[],
           customer:[],
           carat_gold_total:[],
           carat_sliver_total:[],
           sales_summery:[],
           melt_share_summery:[],
      }  
    };  
  async componentDidMount(){
     let checkTokenData = localStorage.getItem("userToken");
        if (this.props.location.state) {  
            let customerParma =  {
		       scrap_id: this.props.location.state.scrapIds,  
		    }; 
        scrapService.getScrapPreviewRecept(customerParma)
        .then(async (result) => {
            let responseJson = result;  
            if(responseJson.status){
              let finalData = responseJson.data.data;  
              this.setState({
                scrap:finalData,  
                metal_job_gold:finalData.metal_job_gold,  
                metal_job_sliver:finalData.metal_job_sliver,  
                putity_gold_total:finalData.putity_gold_total,  
                customer:finalData.customer,  
                carat_gold_total:finalData.carat_gold_total,  
                carat_sliver_total:finalData.carat_sliver_total,  
                sales_summery:finalData.sales_summery,  
                melt_share_summery:finalData.melt_share_summery,  
              });
            } 
        }); 
        }else{
          this.props.history.push(`/scrap-quote`);
        }  
  }  
  render() { 
    return (
      <> 
          <MelboumePreviewReceipt data={this.state.scrap} metalJobGold={this.state.metal_job_gold} metalJobSliver={this.state.metal_job_sliver} putityGoldTotal={this.state.putity_gold_total} customer={this.state.customer} caratGoldTotal={this.state.carat_gold_total} caratSliverTotal={this.state.carat_sliver_total} salesSummery={this.state.sales_summery} meltShareSummery={this.state.melt_share_summery}/> 
      </>
    );
  }
}


