import React from 'react';
import { Router } from "react-router-dom";
import history from "./_services/history";
import Routes from "./routes"; 
import './App.css';  
 
function App() {
  return (
    <div> 
    <Router history={history}> 
      <Routes />
    </Router>
    </div>
  );
}

export default App;
