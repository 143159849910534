import React from "react";
import PropTypes from "prop-types"; 
export default function DefaultLayout({ children }) {
  return(
    <>
      <div className="login-page" style={{minHeight: "512.8px"}}>{children}</div>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired
};
