import axios from "axios"; 
import { authHeader } from '../_helpers';
export const userService = {
    getUsers,
    createUser,
    updateUser
}; 
 

function getUsers() {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.get(`${window.$URL}user-list`, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function createUser(parma) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.post(`${window.$URL}user-add`, parma, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function updateUser(parma) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.post(`${window.$URL}user-update`, parma, requestOptions)
        .then((response) => response)
        .catch((error) => error);
}
 
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text); 
        return data;
    });
}