import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { scrapService,configService } from '../../_services'; 
import { ToastContainer, toast } from 'react-toastify'; 
import staffimg1 from "../../../src/assets/images/staffimg1.png";
import staffimg2 from "../../../src/assets/images/staffimg2.png";
import staffimg3 from "../../../src/assets/images/staffimg3.png";
import staffimg4 from "../../../src/assets/images/staffimg4.png";
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default class SatffHomepage extends Component { 
  constructor (props) {
    super(props)
    this.state = {
      filterScrap: [],
      StatusFilterScrap: [],
      scrapeChecked:[],
      scrapIds:[],
      checkedAll:false,
      process_date:'',
      track_id:'',
      docat_no:'',
      sort_by:'',
      status_type:'',
      isPrevioue:true,
    };
    this.handleChecked = this.handleChecked.bind(this);
  }
  scrapMasterList = () =>{
    let checkTokenData = localStorage.getItem("userToken");
    if (checkTokenData) {
      let checkToken = JSON.parse(checkTokenData);
      //
      let accessToken = checkToken.access_token;

      this.setState({
        name: checkToken.name,
        role: checkToken.role,
        token: accessToken
      });
       scrapService.getScrapList("0")
        .then(async (result) => {
            let response = result;  
            if(response.status){ 
                  if (response.data.status) {
                  let totalCount = response.data.data.length; 
                  this.setState({
                    Details: response.data.data,
                    filterScrap: response.data.data,
                    pagelaoder: false
                  });
                  if (totalCount === 0) {
                    toast.error("No result found", {
                      position: "bottom-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
               }
            }  
        });
        scrapService.getScrapList("1")
        .then(async (result) => {
            let response = result; 
            if(response.status){ 
                  if (response.data.status) {
                  let totalCount = response.data.data.length; 
                  this.setState({
                    StatusDetails: response.data.data,
                    StatusFilterScrap: response.data.data,
                    pagelaoder: false
                  });
                  if (totalCount === 0) {
                    toast.error("No result found", {
                      position: "bottom-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
               }
            } 
            
        }); 
      if (this.props.match.params.hasOwnProperty('clientId')) {

      }
    }
  }
  async componentDidMount(){
    this.scrapMasterList();
  } 
   handlePageChange(pageNumber) {
    const { Details } = this.state;
    const filterScrap = Details;
    this.setState({ filterScrap });
  }
  onInputChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
      let isPrevioue = false;
      if(name == "status_type" && value == '0'){
        isPrevioue = true;
      }  
      this.setState({ 
          [name]: value,
          isPrevioue:isPrevioue
      }); 
  }  
  handleChecked(event) {   
      var isChecked = event.target.checked;  
      var item = event.target.value; 
      let filterScrap = this.state.filterScrap;
      let scrapIds = [];
      filterScrap.forEach(scrap => { 
        if (scrap.id == item){
          scrap.checked = event.target.checked; 
        } 
        if(scrap.checked == true){ 
            scrapIds.push(scrap.id);
          }  

      }); 
      this.setState({ filterScrap: filterScrap,scrapIds:scrapIds});
  }
  onChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
      this.setState({ [name]: value }); 
  } 
  handleSendToMelboume = event => {  
    let statusParma = {
      status:'1',
      sale_date:this.state.process_date,
      track_id:this.state.track_id,
      scrap_id:this.state.scrapIds
    }  
    scrapService.processScrapPurchase(statusParma)
    .then(async (result) => {
        let responseJson = result;  
        if(responseJson.status){
              let finalData = responseJson.data;  
               this.props.history.push({ 
               /*pathname: '/scrap-quote/melboume-status' */
            });   
          this.scrapMasterList();
        } 
    });
  }
  handleSearchDocatNumber = event => {   
    scrapService.searchScrapMaster("0",this.state.docat_no)
    .then(async (result) => {
        let response = result;  
        if(response.status){ 
              if (response.data.status) {
              let totalCount = response.data.data.length; 
              this.setState({
                Details: response.data.data,
                filterScrap: response.data.data,
                pagelaoder: false
              });
              if (totalCount === 0) {
                toast.error("No result found", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
           }
        }  
    });
    scrapService.searchScrapMaster("1",this.state.docat_no)
    .then(async (result) => {
        let response = result; 
        if(response.status){ 
              if (response.data.status) {
              let totalCount = response.data.data.length; 
              this.setState({
                StatusDetails: response.data.data,
                StatusFilterScrap: response.data.data,
                pagelaoder: false
              });
              if (totalCount === 0) {
                toast.error("No result found", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
           }
        }
    }); 
  }    
  handleSortOrderBy = event => {
    scrapService.searchScrapMasterOrderBy("0",this.state.sort_by)
    .then(async (result) => {
        let response = result;  
        if(response.status){ 
              if (response.data.status) {
              let totalCount = response.data.data.length; 
              this.setState({
                Details: response.data.data,
                filterScrap: response.data.data,
                pagelaoder: false
              });
              if (totalCount === 0) {
                toast.error("No result found", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
           }
        }  
    });
    scrapService.searchScrapMasterOrderBy("1",this.state.sort_by)
    .then(async (result) => {
        let response = result; 
        if(response.status){ 
              if (response.data.status) {
              let totalCount = response.data.data.length; 
              this.setState({
                StatusDetails: response.data.data,
                StatusFilterScrap: response.data.data,
                pagelaoder: false
              });
              if (totalCount === 0) {
                toast.error("No result found", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
           }
        }
    }); 
  }    
  onKeyPressEvent = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!(new RegExp("[0-9]").test(keyValue)))
      event.preventDefault();
    return;
  }
  onChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
      this.setState({ [name]: value }); 
  }
  handleAllChecked = event => { 
    var isChecked = event.target.checked;  
      var item = event.target.value; 
      let filterScrap = this.state.filterScrap;
      var scrapIds = [];
      filterScrap.forEach(scrap => {  
          scrap.checked = event.target.checked;
          if(scrap.checked == true){ 
            scrapIds.push(scrap.id);
          } 
      }); 
      this.setState({ filterScrap: filterScrap,checkedAll:isChecked,scrapIds:scrapIds});
  };
  handleDateChange = (name,date) => {  
    if(date){
      this.setState({
        [name]: moment(moment(date)), 
      })
    }else{
        this.setState({
            [name]: "", 
          })
    }
  }
  render() { 
    const { filterScrap,StatusFilterScrap } = this.state;  
    return (
      <>
        <section className="content-header">
        
            <div className="page-quick-links text-right">
                <label>Select to View:</label>
                <Link to="/" className="">Previoue Metal Purchase</Link>
                <Link to="/" className="">Loan Management</Link>
            </div>
            <div className="staffpanel-menu-wrap boxy">
            
                <div className="box">
                    <div className="staffpanel_menu">
                       <Link to="/scrap-quote/create" className=""><img src={staffimg1} alt="" className="" /></Link> 
                    </div> 
                </div>
                <div className="box">
                    <div className="staffpanel_menu">
                       <Link to="/" className=""><img src={staffimg2} alt="" className="" /></Link> 
                    </div> 
                </div> 
                <div className="box">
                    <div className="staffpanel_menu">
                       <Link to="/" className=""><img src={staffimg3} alt="" className="" /></Link> 
                    </div> 
                </div>
                <div className="box">
                    <div className="staffpanel_menu">
                       <Link to="/" className=""><img src={staffimg4} alt="" className="" /></Link> 
                    </div> 
                </div>                
            </div>             
        </section>  
      <section className="page-block">
        <div className="page-block-header">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                  <p className="boldtext">Recent Scrap Purchase From Brisbane (Pending Postage To Melboume)</p>
              </div>
              <div className="col-sm-12 col-md-4 flexy">
                  <div>
                    <label> Docket Search:</label><input type="text" name="docat_no" value={ this.state.docat_no}  onChange={this.onChange} onKeyPress={this.onKeyPressEvent}/><button type="button"  onClick={this.handleSearchDocatNumber}>Search</button> 
                  </div>
                  <div>
                    <label> Sort Order:</label>
                      <select name="sort_by" onChange={this.onChange}>
                        <option value="">Select</option> 
                        <option value="sale_date">Date</option>
                        <option value="created_by">Staff</option>
                      </select>
                    <button type="button" onClick={this.handleSortOrderBy}>Change</button>
                  </div>
              </div>
            </div>
        </div> 
        <div className="table-responsive p-0">
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th>Docket No</th>
                <th>Customer</th>
                <th>Date / Time</th>
                <th>Payment</th>
                <th>Staff</th>
                <th className="textcenter">View</th> 
                <th className="checkboxn">
                  Update Status
                  <div>
                      <input
                      type="checkbox"
                      onClick={this.handleAllChecked}
                      value="checkedall"
                      checked={this.state.checkedAll}
                      /> 
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {filterScrap.length > 0 ? filterScrap.map((data, index) => {
                return (
                  <tr> 
                    <td>{data.order_id}</td>
                    <td>{data.docket_no}</td>
                    <td>{data.customer_name}</td>
                    <td>{data.sale_date_time}</td>
                    <td>{data.payment}</td>
                    <td>{data.staff}</td>
                    <td className="listlink"> 
                        <Link className="" to={{pathname: "/scrap-quote/customer-receipt", state: {scrap_id: data.id}}}>View Receipt </Link>
                        <Link className="" to={{pathname: "/scrap-quote/transication-receipt", state: {scrap_id: data.id}}}>Transication Receipt </Link>
                    </td>  
                    <td className="textcenter">
                    <input  
                      type="checkbox"  
                      value={data.id}  
                      onChange={this.handleChecked}   
                      checked={data.checked} 
                    /></td>  
                  </tr>
                )
              })
                :
                <tr>
                  <td colSpan={8}>No result found</td>
                </tr>
              }
            </tbody>
          </table>
          <div className="row flextable">
            <div className="col-sm-12 col-md-3">
              <div className="form-group">
                <label>Process / Previoue </label>
                <select className="form-control" name="status_type" onChange={this.onInputChange}>
                  <option value="0"> Preview </option>
                  <option value="1"> Process </option>
                </select>
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="form-group">
                <label>Date Process </label>
                 <DatePicker placeholderText="dd-mm-yyyy" dateFormat="dd-MM-yyyy" className="form-control " value={ this.state.process_date} selected={this.state.process_date && new Date(this.state.process_date)} name="sale_date"   onChange={(date) => {
                    this.handleDateChange('process_date',date); 
                  }}  />
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
              <div className="form-group">
                <label>Post Treak ID</label>
                <input type="text" className="form-control" name="track_id"  value={ this.state.track_id}  onChange={this.onChange} onKeyPress={this.onKeyPressEvent}/> 
              </div>
            </div>
            <div className="col-sm-12 col-md-3">
               {this.state.scrapIds.length > 0 ?
                  <div className="form-group">
                      {this.state.isPrevioue ? 
                        <Link to={{pathname: "/scrap-quote/melboume-preview", state: {scrapIds: this.state.scrapIds}}} className="btn btn-md btn-success">Send to Melt Job Preview</Link>
                        :
                        <button type="button" className="btn btn-md btn-success" onClick={this.handleSendToMelboume}>Send to Melt Job</button>
                      }
                  </div>
                  :
                   <div className="form-group">
                       <button type="button" className="btn btn-md btn-success" disabled>Select Transication </button>
                  </div>
                }
            </div>
          </div>
          <div className="row mt-5">
            <div class="col-sm-12 col-md-8 page-block-header">
              <p class="boldtext">Melt Jobs Progress (Pending Completion)</p>
            </div>
          </div>
          <table className="table table-hover text-nowrap">
            <thead>
              <tr>
                <th>#</th>
                <th>Docket No</th>
                <th>Customer</th>
                <th>Date / Time</th>
                <th>Payment</th>
                <th>Staff</th> 
              </tr>
            </thead>
            <tbody>
              {StatusFilterScrap.length > 0 ? StatusFilterScrap.map((data, index) => {
                return (
                  <tr> 
                    <td>{data.order_id}</td>
                    <td>{data.docket_no}</td>
                    <td>{data.customer_name}</td>
                    <td>{data.sale_date_time}</td>
                    <td>{data.payment}</td>
                    <td>{data.staff}</td> 
                  </tr>
                )
              })
                :
                <tr>
                  <td colSpan={6}>No result found</td>
                </tr>
              }
            </tbody>
          </table>
        </div> 
      </section>
      </>
    );
  }
}
