import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { scrapService,configService,customerService } from '../../_services';  
import PrintReceipt from '../../component/PrintReceipt';  
import PrintCustomerReceipt from '../../component/PrintCustomerReceipt';  
import axios from "axios";
import PrintComponents from "react-print-components";
export default class CustomerReceipt extends Component { 
 constructor(props) {
    super(props); 
      this.state = {
           scrap_id:'', 
           custom_scraps:[],
           scrapTotal:'', 
           ownerDetail:[],
           trasactionDetail:'',
           goldItems:[],
           goldDetails:[],
      }  
    };  
  async componentDidMount(){
     let checkTokenData = localStorage.getItem("userToken");
        if (this.props.location.state) { 
            const scrapId =  this.props.location.state.scrap_id;
              this.setState({
                scrap_id:this.props.location.state.scrap_id,
                custom_scraps:this.props.location.state.custom_scraps, 
              });
              scrapService.getOrderRecept(scrapId)
              .then(async (result) => {
                  let responseJson = result; 
                   
                  if(responseJson.status){
                        let finalData = responseJson.data.data; 
                        this.setState({
                          ownerDetail:finalData.owner_detail,
                          trasactionDetail:finalData.trasaction_detail,
                          custom_scraps:finalData.custom_scrap, 
                          goldItems:finalData.custom_scrap.gold.items, 
                          goldDetails:finalData.custom_scrap.gold, 
                        }); 
                  } 
              });
        }else{
          this.props.history.push(`/scrap-quote`);
        }  
  }  
  render() { 
    return (
      <> 
          <PrintCustomerReceipt data={this.state} goldItems={this.state.goldItems} goldDetails={this.state.goldDetails} ownerDetail={this.state.ownerDetail} trasactionDetail={this.state.trasactionDetail}/> 
      </>
    );
  }
}
