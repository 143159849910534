import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import Login from '../pages/login';
import ForgotPassword from '../pages/Forgotpassword';
import Dashboard from '../pages/dashboard'; 
import Reset from '../pages/Resetpassword';  
import Logout from '../pages/Logout'; 
import Profile from '../pages/Profile';
import editProfile from '../pages/Profile/editProfile'; 
import CustomResetpassword from '../pages/CustomResetpassword'; 
import Scrapquote from '../pages/Scrapquote'; 
import ScrapquoteCreate from '../pages/Scrapquote/Create'; 
import ScrapquoteCustomerDetails from '../pages/Scrapquote/CustomerDetails'; 
import ScrapquoteOrderReceipt from '../pages/Scrapquote/OrderReceipt'; 
import ScrapquoteCustomerReceipt from '../pages/Scrapquote/CustomerReceipt';  
import ScrapquoteTransicationReceipt from '../pages/Scrapquote/TransicationReceipt'; 
import MelboumePreviewReceipt from '../pages/Scrapquote/MelboumePreview';  
import MelboumeStatus from '../pages/Scrapquote/MelboumeStatus';  
import Settings from '../pages/Setting';  
import User from '../pages/Users';  
import UserCreate from '../pages/Users/UserCreate';  
import StaffHomepage from '../pages/StaffHomepage';   

let resetURL = window.location.pathname.split("/")[1];

export default function Routes() {
    return (
        <Switch>
            <Route path='/profile' exact component={Profile} isPrivate/>    
            <Route path='/editProfile' exact component={editProfile} isPrivate/>  
            <Route path='/dashboard' exact component={Dashboard} isPrivate /> 
            <Route path='/staff-panel' exact component={StaffHomepage} isPrivate /> 
            <Route path='/login' exact component={Login} /> 
            <Route path='/forgot-password' exact component={ForgotPassword} />
            <Route path='/reset/:token' exact component={Reset} />
            <Route path='/logout' exact component={Logout} isPrivate isGrantAccess />  
            <Route path="/update-reset-password/:email" exact component={CustomResetpassword} />
            <Route path="/scrap-quote" exact component={Scrapquote} isPrivate/>
            <Route path="/scrap-quote/melboume-status" exact component={MelboumeStatus} isPrivate/>
            <Route path="/scrap-quote/create" exact component={ScrapquoteCreate} isPrivate/>
            <Route path="/scrap-quote/customer-details" exact component={ScrapquoteCustomerDetails} isPrivate/>
            <Route path="/scrap-quote/order-receipt" exact component={ScrapquoteOrderReceipt} isPrivate/>
            <Route path="/scrap-quote/customer-receipt" exact component={ScrapquoteCustomerReceipt} isPrivate isReceipt/>
            <Route path="/scrap-quote/transication-receipt" exact component={ScrapquoteTransicationReceipt} isPrivate isReceipt/>
            <Route path="/scrap-quote/melboume-preview" exact component={MelboumePreviewReceipt} isPrivate isReceipt/> 
            <Route path="/settings" exact component={Settings} isPrivate/> 
            <Route path="/users" exact component={User} isPrivate/> 
            <Route path="/user/create" exact component={UserCreate} isPrivate/> 
            <Route path="/user/edit" exact component={UserCreate} isPrivate/> 
            <Route component={resetURL === 'reset' ? Reset : Login} />  
        </Switch>
    )
}