import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import Pagination from "react-js-pagination";
import profileImg from "../../assets/images/sign.png";
 
export default class Profile extends Component {


  state = {
    token:'',
    role:'',
    email: '', 
    firstName: '',
    lastName: '', 
    middleName:'', 
    phone:'',
}
    async componentDidMount() { 
        let checkTokenData = localStorage.getItem("userToken"); 
        if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
            
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token:accessToken
            })
            this.getUserDetails();
        } 
    } 
    async getUserDetails()
    { 
        let checkTokenData = localStorage.getItem("userToken");
        if(checkTokenData){
            let checkToken = JSON.parse(checkTokenData);
    
            let accessToken = checkToken.access_token;
    
            //get listing 
            await axios.get(`${window.$URL}view-profile`,{
                params: { 
                },
                headers: { "Authorization": `Bearer ${accessToken}` }
            })
            .then(response => {
                
                if (response.data.status) {  
                    this.setState( {
                        email:response.data.data.email,
                        firstName:response.data.data.first_name,
                        lastName:response.data.data.last_name,
                        middleName:response.data.data.middle_name, 
                        phone:response.data.data.phone, 
                        }); 
                }
            })
            .catch(error => {
                console.error(error.data)
            })
                //end listing

        } 
    }
   

    render() {
        const { 
            userId,
            documentType,            
            documentNumber,
            country,
            success,
            error,
            successMessage,
            errorMessage
        } = this.state;
        return (
            <div className="wrapper">
              <ToastContainer />  
              <div className="container newContainer mainArea pt-0" id="formpage">
                <div className="row justify-content-center"> 
                  <div className="lefeArea col-md-9 p-0">  
                    <div className="content-wrap">
                      <div className="row justify-content-center"> 
                        <div className="lefeArea col-md-6">  
                          <h4 className="smallheading mb-0">Profile Details</h4>
                          <div className="tablebox">  
                                <div className="form-group row">
                                    <label for="" className="col-sm-4 col-form-label">Email </label>
                                    <div className="col-sm-8">
                                    <input type="email" className="form-control" value={this.state.email} readOnly/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="" className="col-sm-4 col-form-label">First Name </label>
                                    <div className="col-sm-8">
                                    <input type="text" className="form-control" value={this.state.firstName} readOnly/>
                                    </div>
                                </div>  
                                <div className="form-group row">
                                    <label for="" className="col-sm-4 col-form-label">Middle Name </label>
                                    <div className="col-sm-8">
                                    <input type="text" className="form-control" value={this.state.middleName} readOnly/>
                                    </div>
                                </div> 
                                <div className="form-group row">
                                    <label for="" className="col-sm-4 col-form-label">Last Name </label>
                                    <div className="col-sm-8">
                                    <input type="text" className="form-control" value={this.state.lastName} readOnly/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label for="" className="col-sm-4 col-form-label">Phone</label>
                                    <div className="col-sm-8">
                                    <input type="tel" className="form-control" value={this.state.phone} readOnly/>
                                    </div>
                                </div>
                              <div className="form-group row text-center mt-2 ml-auto">
                                <div className="col-sm-12">
                                <div className="m-auto buttonwrp text-center">  
                                    <Link to="/editProfile" className="btn btn-primary greybtn">Edit</Link>  
                                </div>
                                </div>
                              </div>
                          </div> 
                        </div>  
                      </div>
                    </div> 
                  </div> 
                </div>
              </div> 
            </div> 
        );
    }
}
