import React from 'react'
import { Link } from 'react-router-dom'; 
import PropTypes from "prop-types";  
import Header from './Header';
import Footer from './Footer';
import Topbar from './Topbar';
//import Sidebar from './Sidebar'; 

import '../../assets/css/custom.css'; 
import '../../assets/css/masterv3.css'; 
//const deviceWidth = window.innerWidth > 650 ? true : false

export default function AuthLayout({ children }) { 
    return (
        <> 
            <div className="wrapper">
                <Header/> 
                <Topbar/> 
                <div className="content-wrapper"> 
                    <div className="content-header full_header">
                            {children}
                    </div>
                </div>
                <aside className="control-sidebar control-sidebar-dark">
                    <ul className="adminmenu">  
                     <li>
                        <Link to="/logout"><i className="fa fa-sign-in"></i> Log Out</Link>  
                      </li> 
                    </ul>  
                </aside>
                <Footer />
            </div> 
        </>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.element.isRequired
};

