import React, { Component,useEffect, useState } from "react"; 
import { Link } from 'react-router-dom'; 
import MessageCount from '../../messageCount'; 
export default function Header() {
  const [small, setSmall] = useState(false);
  let checkTokenData = localStorage.getItem("userToken");
  let checkToken = JSON.parse(checkTokenData);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 63)
      );
    }
  }, []);
  
  
    return ( 
      <div className="top-bar-wrap">
        <div className="wrp">
        
              <div className="boxd"><label className="form-label">Current Location</label></div>
              <div className="boxd">
                <select className="form-control" name="purity_type"> 
                  <option value="Brisbane">Brisbane</option>
                </select> 
              </div>
              <div className="boxd">
                <button type="button" className="btn btn-sm btn-success">Swap Location</button>
              </div>
              
        </div>
      </div>
    ); 
}
