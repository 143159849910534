import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios"; 
import { settingService } from '../../_services'; 
import { ToastContainer, toast } from 'react-toastify'; 
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import BtnLoader from '../../component/Btnloader';  
export default class Settings extends Component {
  constructor (props) {
    super(props)
    this.state = {
      settings: [],
      editAllRate : false, 
      editAll:false,
      saveBtn:false
    };
    
  }
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    let customChoice = this.state.value;
    if (checkTokenData) {
      let checkToken = JSON.parse(checkTokenData); 
      let accessToken = checkToken.access_token; 
      this.setState({
        name: checkToken.name,
        role: checkToken.role,
        token: accessToken
      }); 
      this.getSettings(); 
    }
  }
  getSettings(){
    settingService.getSettings()
      .then(async (result) => {
        let response = result; 
        if(response.status){ 
              if (response.data.status) {
              let totalCount = response.data.data.length; 
              this.setState({
                settings: response.data.data, 
              });
              if (totalCount === 0) {
                toast.error("No result found", {
                  position: "bottom-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
           }
        }  
      });
  }
  
  onSettingUpdate = (event,x) => { 
    let settings = [...this.state.settings]; 
    let parma = { 'seetings':settings };
     settingService.settingUpdate(parma)
        .then(async (result) => {
          let response = result; 
          if (response.data.status) { 
               toast.success("Price update successfully", {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
           }  
          settings.forEach(setting => {  
            setting.price_edit =  false;
            setting.rate_edit =  false;
          });
          this.setState({ settings });
      });
      
  } 
    onSettingEditChnage(i, event) {
    let customChoice = {}; 
    let settings = [...this.state.settings]; 
    let values = [...this.state.settings];
     const {name,value,carat} = event.target; 
     console.log(name); 
     if(name == 'percentage'){
       values[i].percentage = value;
        values[i].caret =  carat;
     }
     if(name == 'type'){
       values[i].type = value;
       values[i].caret =  carat;
     } 
     customChoice = {
          'rate_price' : value, 
          'type':name,
          'carat':values[i].caret,
      } 
     values[i][name] = value; 
     this.setState({ values });  
  }
  onRateEdit(event) {
    let settings = [...this.state.settings]; 
    let status =  this.state.editAllRate ? false : true;
    settings.forEach(setting => {  
      setting.rate_edit =  status;
         
    }); 
    this.setState({ settings: settings,editAllRate:status,saveBtn:status}); 
 }
 onAllEdit(event) {
  let settings = [...this.state.settings]; 
  let status =  this.state.editAll ? false : true;
  settings.forEach(setting => {  
    setting.rate_edit  =  status;
    setting.price_edit =  status;
       
  }); 
  this.setState({ settings: settings,editAll:status,saveBtn:status});
}
  render() {   
    return (
      <>
        <section className="content-header">
          <ToastContainer/>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1> Settings </h1> 
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Settings</li>
                </ol>
              </div>
            </div>
          </div>
        </section> 
        <section className="setting-wrap">
          <div className="card"> 
            <div className="card-body table-responsive p-0">
              <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                      <th colSpan="3">
                        {!this.state.editAll &&
                        <button className="btn btn-sm btn-primary" onClick={() => this.onRateEdit()}>Edit All Rate</button>
                        }
                      </th>
                      <th>
                        {this.state.saveBtn &&
                        <button className="btn btn-sm btn-success"  onClick={() => this.onSettingUpdate()}>Save Settings</button>
                        } 
                        </th> 
                      </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="10%">Carat</td>
                    <td width="10%">Purity</td>
                    <td width="10%">Rate</td>
                    <td width="10%">Price</td>
                  </tr> 
                   {this.state.settings.length > 0 ? this.state.settings.map((data, index) => {
                    return (
                      <tr>
                        <td>
                          {data.caret}
                          {data.caret_edit &&
                            <input type="number" className="form-control" name="caret" value={data.caret} onChange={this.onSettingEditChnage.bind(this, index)}/>
                          }  
                          </td>
                        <td>
                            {data.percentage}
                            {data.percentage_edit &&
                              <input type="number" className="form-control" name="percentage" value={data.percentage} onChange={this.onSettingEditChnage.bind(this, index)}/>
                            }      
                        </td>
                        <td>
                          {data.rate}%
                          {data.rate_edit &&
                            <input type="number"  max="100" className="form-control" name="rate" value={data.rate} onChange={this.onSettingEditChnage.bind(this, index)}/>
                          }  
                        </td>
                        <td>
                          {data.price}
                          {data.price_edit &&
                           <input type="number" className="form-control" name="price" value={data.price} onChange={this.onSettingEditChnage.bind(this, index)}/>
                          }  
                        </td>
                      </tr>
                     )
                    })
                      :
                      <div className="col-sm-12">
                        <p>No result found</p>
                      </div>
                    }
                </tbody>
              </table>
            </div>
          </div> 
        </section>
      </>
    );
  }
}
