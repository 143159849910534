import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import { scrapService,configService } from '../../_services'; 
import { ToastContainer, toast } from 'react-toastify'; 
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default class Dashboard extends Component { 
  constructor (props) {
    super(props)
    this.state = {
      filterScrap: [],
      scrapeChecked:[],
      scrapIds:[],
      checkedAll:false,
      process_date:'',
      status_type:'',
      isPrevioue:true,
    };
    this.handleChecked = this.handleChecked.bind(this);
  }
  async componentDidMount(){
        let checkTokenData = localStorage.getItem("userToken");
    if (checkTokenData) {
      let checkToken = JSON.parse(checkTokenData);
      //
      let accessToken = checkToken.access_token;

      this.setState({
        name: checkToken.name,
        role: checkToken.role,
        token: accessToken
      });
       scrapService.getScrapList("0")
        .then(async (result) => {
            let response = result;  
             
            if(response.status){ 
                  if (response.data.status) {
                  let totalCount = response.data.data.length; 
                  this.setState({
                    Details: response.data.data,
                    filterScrap: response.data.data,
                    pagelaoder: false
                  });
                  if (totalCount === 0) {
                    toast.error("No result found", {
                      position: "bottom-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
               }
            } 
            
        });
      if (this.props.match.params.hasOwnProperty('clientId')) {

      }
    }
  } 
   handlePageChange(pageNumber) {
    const { Details } = this.state;
    const filterScrap = Details;
    this.setState({ filterScrap });
  }
  onInputChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
      let isPrevioue = false;
      if(name == "status_type" && value == '0'){
        isPrevioue = true;
      }  
        this.setState({ 
                [name]: value,
                isPrevioue:isPrevioue
        }); 
  }  
  handleChecked(event) {   
      var isChecked = event.target.checked;  
      var item = event.target.value; 
      let filterScrap = this.state.filterScrap;
      let scrapIds = [];
      filterScrap.forEach(scrap => { 
        if (scrap.id == item){
          scrap.checked = event.target.checked; 
        } 
        if(scrap.checked == true){ 
            scrapIds.push(scrap.id);
          }  

      }); 
      this.setState({ filterScrap: filterScrap,scrapIds:scrapIds});
  }  
  handleSendToMelboume = event => {   
    
    let statusParma = {
      status:'1',
      sale_date:this.state.process_date,
      scrap_id:this.state.scrapIds
    }  
    scrapService.processScrapPurchase(statusParma)
            .then(async (result) => {
                let responseJson = result;  
                if(responseJson.status){
                      let finalData = responseJson.data;  
                       this.props.history.push({ 
                       pathname: '/scrap-quote/melboume-status' 
                      });   
                } 
            });
  }  
  handleAllChecked = event => { 
    var isChecked = event.target.checked;  
      var item = event.target.value; 
      let filterScrap = this.state.filterScrap;
      var scrapIds = [];
      filterScrap.forEach(scrap => {  
          scrap.checked = event.target.checked;
          if(scrap.checked == true){ 
            scrapIds.push(scrap.id);
          } 
      }); 
      this.setState({ filterScrap: filterScrap,checkedAll:isChecked,scrapIds:scrapIds});
  };
  handleDateChange = (name,date) => {  
    if(date){
      this.setState({
        [name]: moment(moment(date)), 
      })
    }else{
        this.setState({
            [name]: "", 
          })
    }
  }
  render() { 
    const { filterScrap } = this.state; 
    
    return (
             <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row topcontent">
              <div>
                <h1>
                  Scrape List 
                  <Link to="/scrap-quote/create" className="btn btn-sm btn-primary  ml-2">Create Quote</Link>  
                  <Link to="/scrap-quote/melboume-status" className="btn btn-sm btn-info ml-2">Melboume Status</Link></h1>
              </div>
              <div>
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">DataTables</li>
                </ol>
              </div>
            </div>
          </div>
        </section> 
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header"> 
                <div className="row">
                    <div className="col-sm-12 col-md-2">
                      <div className="form-group">
                        <label>Process / Previoue </label>
                        <select className="form-control" name="status_type" onChange={this.onInputChange}>
                          <option value="0"> Preview </option>
                          <option value="1"> Process </option>
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="form-group">
                        <label>Date Process </label>
                         <DatePicker placeholderText="dd-mm-yyyy" dateFormat="dd-MM-yyyy" className="form-control " value={ this.state.process_date} selected={this.state.process_date && new Date(this.state.process_date)} name="sale_date"   onChange={(date) => {
                            this.handleDateChange('process_date',date); 
                          }}  />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-2">
                      <div className="form-group">
                        <label>Post Treak ID</label>
                        <input type="text" className="form-control" /> 
                      </div>
                    </div>
                     <div className="col-sm-12 col-md-2 mt-3">
                     {this.state.scrapIds.length > 0 ?
                        <div className="form-group mt-3">
                            {this.state.isPrevioue ? 
                              <Link to={{pathname: "/scrap-quote/melboume-preview", state: {scrapIds: this.state.scrapIds}}} className="btn btn-md btn-success">Send to Melt Job Preview</Link>
                              :
                              <button type="button" className="btn btn-md btn-success" onClick={this.handleSendToMelboume}>Send to Melt Job</button>
                            }
                        </div>
                        :
                         <div className="form-group mt-3">
                             <button type="button" className="btn btn-md btn-success" disabled>Select Transication </button>
                        </div>
                      }
                    </div>
                </div>
              </div>
              <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Docket No</th>
                      <th>Customer</th>
                      <th>Date / Time</th>
                      <th>Payment</th>
                      <th>Staff</th>
                      <th>View</th> 
                      <th>
                        Update Status
                        <div>
                            <input
                            type="checkbox"
                            onClick={this.handleAllChecked}
                            value="checkedall"
                            checked={this.state.checkedAll}
                            />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterScrap.length > 0 ? filterScrap.map((data, index) => {
                      return (
                        <tr> 
                          <td>{data.order_id}</td>
                          <td>{data.docket_no}</td>
                          <td>{data.customer_name}</td>
                          <td>{data.sale_date_time}</td>
                          <td>{data.payment}</td>
                          <td>{data.staff}</td>
                          <td class="listlink"> 
                              <Link to={{pathname: "/scrap-quote/customer-receipt", state: {scrap_id: data.id}}}>View Receipt </Link>
                              <Link to={{pathname: "/scrap-quote/transication-receipt", state: {scrap_id: data.id}}}>Transication Receipt </Link>
                          </td>  
                          <td>
                          <input  
                            type="checkbox"  
                            value={data.id}  
                            onChange={this.handleChecked}   
                            checked={data.checked} 
                          /></td>  
                        </tr>
                      )
                    })
                      :
                      <tr>
                        <td colSpan={8}>No result found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
