
import { authHeader } from '../_helpers';

export const configService = {
    getConfig, 
};  
function getConfig(type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    }; 
    return new Promise((resolve, reject) =>{
        fetch(`${window.$URL}get-config?${type}`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
            resolve(res);
        })
        .catch((error) => {
             reject(error);
        });
    });
}
 