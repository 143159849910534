import axios from "axios"; 
import { authHeader } from '../_helpers';
export const scrapService = {
    getScrapList,
    createQuote,
    calculateQuote,
    customerQuoteDetailSave,
    getScrapPreviewRecept,
    getOrderRecept,
    processScrapPurchase,
    searchScrapMaster,
    searchScrapMasterOrderBy,
}; 
 
function getScrapList(status) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.get(`${window.$URL}scrap-purchase-list?status=${status}`, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
} 
function createQuote(quoteParma) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.post(`${window.$URL}create-gold-sliver-scrap`, quoteParma, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function calculateQuote(quoteParma) {
     const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.post(`${window.$URL}calculate-gold-sliver`, quoteParma, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function customerQuoteDetailSave(customerDteails) {
     const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.post(`${window.$URL}create-customer`, customerDteails, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function getOrderRecept(scrapId) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.get(`${window.$URL}print-transaction-register?scrap_id=${scrapId}`, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function getScrapPreviewRecept(scrapIds) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
    return axios.post(`${window.$URL}preview-scrap-purchase`, scrapIds, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function processScrapPurchase(statusParma) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
    return axios.post(`${window.$URL}process-scrap-purchase`, statusParma, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function searchScrapMaster(status,docat_no) { 
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.get(`${window.$URL}scrap-search?status=${status}&&docket_no=${docat_no}`, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
} 

function searchScrapMasterOrderBy(status,sortBy) {
    const requestOptions = { 
        headers:  authHeader(),
    }; 
     return axios.get(`${window.$URL}scrap-order-by?status=${status}&&sort_by=${sortBy}`, requestOptions)
        .then((response) => response)
        .catch((error) => {
           
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text); 
        return data;
    });
}