import React, { Component,useEffect, useState } from "react"; 
import { Link } from 'react-router-dom'; 
import MessageCount from '../../messageCount'; 
import logo from '../../../assets/images/logo.png';
export default function Header() {
  const [small, setSmall] = useState(false);
  let checkTokenData = localStorage.getItem("userToken");
  let checkToken = JSON.parse(checkTokenData);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 63)
      );
    }
  }, []);
  
  
    return ( 
      <div className="headerm">
      <nav className="main-header navbar navbar-expand navbar-white navbar-light full_nav"> 
        <img src={logo} alt="" className="brand-image" />
        <ul className="navbar-nav ul-nav"> 
          <li className="nav-item">
            <Link to="/staff-panel" className="nav-link"><p>Staff Homepage</p></Link>  
          </li>
          <li className="nav-item">
            <Link to="/" className="nav-link"><p>Scrap Purchase</p></Link>  
          </li> 
          <li className="nav-item">
            <Link to="/" className="nav-link"><p>Bullion Purchase</p></Link>  
          </li>
          <li className="nav-item dropdown">
            <Link to="/" className="nav-link dropdown-toggle" id="memberDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Management</Link>
            <div className="dropdown-menu bg-black" aria-labelledby="memberDropdown">
               <Link to="/settings" className="nav-link"><p>Setting</p></Link>
            </div>
          </li>
           <li className="nav-item"> 
            {/* <Link to="/logout" className=" btn btn-sm btn-danger text-white ml-2"><i className="fa fa-sign-in"></i> Log Out</Link>   */}
            <Link to="/logout" className="nav-link"><i className="fa fa-sign-in"></i> Log Out</Link>  
          </li> 
        </ul>
      </nav> 
      </div>
    ); 
}
