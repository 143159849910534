import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { userService,configService } from '../../_services'; 
import BtnLoader from '../../component/Btnloader';  
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const initialState = {
  createBtnLoader: false
};
export default class Create extends Component { 
  constructor(props) {
    super(props); 
      this.state = {
          'user':{
                id:'',
                email:'',
                password:'',
                title:'',
                first_name:'', 
                middle_name:'', 
                surname:'',  
                phone:'', 
                street_1:'', 
                street_2:'', 
                town:'', 
                state:'', 
                postal_code:'', 
                country:'',  
                dob:'',  
              },
         
          'validationErrors':{
            emailError:'',
            passwordError:'',
            titleError:'',
            firstNameError:'', 
            middleNameError:'', 
            surnameError:'',  
            phoneError:'', 
            street_1Error:'',  
            townError:'', 
            stateError:'', 
            postalCodeError:'', 
            countryError:'',  
            dobError:'', 
          },
          configTitle:[],
          token:'',
          btnLoader:false,
          btnSaveLoader:false,
          isUpdate:false
      }  
    };  
  async componentDidMount(){
     let checkTokenData = localStorage.getItem("userToken");
        if (checkTokenData) {
            let checkToken = JSON.parse(checkTokenData); 
            let accessToken = checkToken.access_token;
            this.setState({
                name: checkToken.name,
                role: checkToken.role,
                token: accessToken
            });
            configService.getConfig('title=true')
            .then(async (result) => {
                let responseJson = result;  
                if(responseJson.status){
                      let finalData = responseJson.data;  
                        this.setState({
                               configTitle:finalData.title,
                        }); 
                } 
            });
           if (this.props.location.state) { 
              this.setState({
                user:this.props.location.state.user,
                isUpdate:true, 
              }); 
           }  


        }
  }
  onChange = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
      this.setState({ [name]: value }); 
  } 
 
  validate = () => {
    let validateStatus = true;
      const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
       const onlyNumberRegExp = /^[0-9\b]+$/;
      if (this.state.user.email < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{emailError:'Please enter email'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{emailError:''}));
      }  
      if(!this.state.isUpdate){
        if (this.state.user.password < 1) {   
            this.setState(Object.assign(this.state.validationErrors,{passwordError:'Please enter password'}));
            validateStatus = false;
        }else{ 
            this.setState(Object.assign(this.state.validationErrors,{passwordError:''}));
        }
      }
       
      if (this.state.user.title < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{titleError:'Please select title'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{titleError:''}));
      }
      if (this.state.user.first_name < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{firstNameError:'Please enter first name'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{firstNameError:''}));
      } 
      if (this.state.user.street_1 < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{street_1Error:'Please enter address'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{street_1Error:''}));
      }
      if (this.state.user.town < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{townError:'Please enter town'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{townError:''}));
      } 
      if (this.state.user.state < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{stateError:'Please enter state'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{stateError:''}));
      } 
     
      if (this.state.user.postal_code.length < 1) { 
          this.setState(Object.assign(this.state.validationErrors,{postalCodeError:'Please Enter PostalCode'}));
          validateStatus = false;
      }else if (!onlyNumberRegExp.test(this.state.user.postal_code)){
          this.setState(Object.assign(this.state.validationErrors,{postalCodeError:'Please Enter Valid PostalCode'}));
          validateStatus = false;
      }else{
          this.setState(Object.assign(this.state.validationErrors,{postalCodeError:''}));
      }
      if (this.state.user.country < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{countryError:'Please enter country'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{countryError:''}));
      }
      if (this.state.user.doc_type < 1) {   
        console.log(this.state.user.doc_type);
          this.setState(Object.assign(this.state.validationErrors,{docTypeError:'Please enter doc type'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{docTypeError:''}));
      }
      if (this.state.user.country_issue < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{countryIssueError:'Please enter country issue'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{countryIssueError:''}));
      } 
      if (this.state.user.id_number < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{idNumberError:'Please enter id number'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{id_numberError:''}));
      }
      if (this.state.user.dob < 1) {   
          this.setState(Object.assign(this.state.validationErrors,{dobError:'Please enter DOB'}));
          validateStatus = false;
      }else{ 
          this.setState(Object.assign(this.state.validationErrors,{dobError:''}));
      } 
     
       
    return validateStatus;
  } 
  onCustomerInputChnage = (e) => {
      e.preventDefault();
      const { name, value } = e.target; 
       const user = this.state.user;
        this.setState({
          user: {
                ...user,
                [name]: value
            }
        }); 
  }  
  handleDOBDateChange = (name,date) => {  
     const { user } = this.state;
        
    if(date){
       this.setState({
          user: {
                ...user,
                [name]: moment(moment(date)),
            }
        });
    }else{
         this.setState({
          user: {
                ...user,
                [name]: '',
            }
        });
    }
  }
  onSaveUser = (e) => {
    e.preventDefault();
    let validate = this.validate();
    let customerParma = this.state.user;
    if(validate){
      this.setState({ createBtnLoader: true });
      if(this.state.isUpdate){
        userService.updateUser(customerParma).then(async (result) => {
              let responseJson = result.data;  
              console.log(responseJson);
              if(responseJson.success){
                    let finalData = responseJson.data;  
                     this.props.history.push({ 
                     pathname: '/users', 
                    });    
              }else{
                let finalData = result; 
                this.setState(Object.assign(this.state.validationErrors,{emailError:finalData.data.email ?finalData.data.email[0]:''}));
                    
              }  
          }); 
      }else{
        userService.createUser(customerParma).then(async (result) => {
              let responseJson = result.data;  
              if(responseJson.success){
                    let finalData = responseJson.data;  
                     this.props.history.push({ 
                     pathname: '/users', 
                    });    
              }else{
                let finalData = result; 
                this.setState(Object.assign(this.state.validationErrors,{emailError:finalData.data.email ?finalData.data.email[0]:''}));
              }  
          }); 
      }
    } else {
      this.setState({ 
          createBtnLoader: false
      });
    }
  } 
  render() { 
    const { configTitle } = this.state; 
     let configTitleOptions = configTitle.length > 0
        && configTitle.map((item, i) => {
            return (
                <option value={item.value}>{item.value}</option>
            ) 
        }, this);  
    return (
      <>
       <section className="content-header">
           <ToastContainer />  
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>User</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link to="/scrap-quote">User</Link></li>
                  <li className="breadcrumb-item active">{this.state.isUpdate ? 'Update' : 'Create'}</li>
                </ol>
              </div>
            </div> 
          </div>
        </section>
        <section className="form-block"> 
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">User Details</h3>
                </div>
                 <form onSubmit={this.onSaveUser} autocomplete="on"> 
                  <div className="card-body">
                      <div className="form-fields">
                        <div className="row"> 
                          <div className="form-group col-sm-12 col-md-3">
                            <label>Customer Title</label>
                            <select name="title" className="form-control" value={this.state.user.title} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}>
                                <option value="0">Select Title</option>
                                {configTitleOptions}
                              </select>
                            <p className="error">{this.state.validationErrors.titleError}</p>
                          </div>                
                          <div className="form-group col-sm-12 col-md-3">
                            <label>First Name</label>
                            <input type="text" name="first_name" className="form-control" value={this.state.user.first_name} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                             <p className="error">{this.state.validationErrors.firstNameError}</p>
                          </div>                
                          <div className="form-group col-sm-12 col-md-3">
                              <label>Middle Name <span className="text-info">Optional</span></label>
                              <input type="text" name="middle_name" className="form-control"  value={this.state.user.middle_name} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                           </div>                
                          <div className="form-group col-sm-12 col-md-3">
                              <label>Surname</label>
                              <input type="text" name="surname" className="form-control"  value={this.state.user.surname} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                               <p className="error">{this.state.validationErrors.surnameError}</p>
                          </div>  
                          <div className="form-group col-sm-12 col-md-3">
                              <label>Email</label>
                              <input type="email" name="email" className="form-control"  value={this.state.user.email} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                               <p className="error">{this.state.validationErrors.emailError}</p>
                          </div>
                          <div className="form-group col-sm-12 col-md-3">
                              <label>Password</label>
                              <input type="password" name="password" className="form-control"  value={this.state.user.password} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                               <p className="error">{this.state.validationErrors.passwordError}</p>
                          </div>           
                          <div className="form-group col-sm-12 col-md-3">
                              <label>Phone Number <span className="text-info">Optional</span></label>
                              <input type="text" name="phone" className="form-control"  value={this.state.user.phone}onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                          </div>  
                          <div className="form-group col-sm-12 col-md-3">
                            <div><label>DOB</label></div>
                            <DatePicker placeholderText="dd-mm-yyyy" dateFormat="dd-MM-yyyy" className="form-control " value={ this.state.user.dob} selected={this.state.user.dob && new Date(this.state.user.dob)} name="dob"   onChange={(date) => {
                              this.handleDOBDateChange('dob',date); 
                            }}  />
                            <p className="error">{this.state.validationErrors.dobError}</p>
                          </div> 
                          <div className="form-group col-sm-12 col-md-3">
                            <label>Street Address1</label>
                            <input type="text" name="street_1" className="form-control"  value={this.state.user.street_1} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                             <p className="error">{this.state.validationErrors.street_1Error}</p>
                          </div>
                          <div className="form-group col-sm-12 col-md-3">
                            <label>Street Address2 <span className="text-info">Optional</span></label>
                            <input type="text" name="street_2" className="form-control"  value={this.state.user.street_2} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                          </div>
                          <div className="form-group col-sm-12 col-md-3">
                            <label>Town / Suburb</label>
                            <input type="text" name="town" className="form-control"  value={this.state.user.town} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                             <p className="error">{this.state.validationErrors.townError}</p>
                          </div>
                          <div className="form-group col-sm-12 col-md-3">
                            <label>State / Territory</label>
                            <input type="text" name="state" className="form-control"  value={this.state.user.state} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                            <p className="error">{this.state.validationErrors.stateError}</p>
                          </div>
                          <div className="form-group col-sm-12 col-md-3">
                            <label>PostalCode</label>
                            <input type="text" name="postal_code" className="form-control"  value={this.state.user.postal_code} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                             <p className="error">{this.state.validationErrors.postalCodeError}</p>
                          </div>
                          <div className="form-group col-sm-12 col-md-3">
                            <label>Country</label>
                            <input type="text" name="country" className="form-control"  value={this.state.user.country} onChange={this.onCustomerInputChnage} readOnly = {this.state.disabled}/>
                             <p className="error">{this.state.validationErrors.countryError}</p>
                          </div> 
                        </div>
                      </div>
                    </div>
                  <div className="card-footer"> 
                      <button type="submit" className="btn btn-sm btn-success">
                        <div className="buttonloader">
                          <div className={`loader_wrap ${this.state.createBtnLoader ? 'loader_active' : ''}`}> </div>
                        </div>
                        { this.state.isUpdate ? 'Update' : 'Create'}
                      </button>
                  </div> 
                  </form>
              </div> 
        </section> 
      </>
    );
  }
}
