import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from "axios"; 
import { userService } from '../../_services'; 
import { ToastContainer, toast } from 'react-toastify'; 
import moment from "moment";
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css"; 
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
export default class User extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filterScrap: [], 
      Details: [], 
    }; 
  }
  async componentDidMount() {
    let checkTokenData = localStorage.getItem("userToken");
    if (checkTokenData) {
      let checkToken = JSON.parse(checkTokenData);
      //
      let accessToken = checkToken.access_token;

      this.setState({
        name: checkToken.name,
        role: checkToken.role,
        token: accessToken
      });
       userService.getUsers()
        .then(async (result) => {
            let response = result;  
             
            if(response.status){ 
                  if (response.data.status) {
                  let totalCount = response.data.data.length; 
                  this.setState({
                    Details: response.data.data,
                    filterScrap: response.data.data, 
                  });
                  if (totalCount === 0) {
                    toast.error("No result found", {
                      position: "bottom-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
               }
            } 
            
        });
       
    }
  }

  handlePageChange(pageNumber) {
    const { Details } = this.state;
    const filterScrap = Details;
    this.setState({ filterScrap });
  } 
  render() { 
    const { filterScrap } = this.state; 
         
    return (
      <>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>
                  Users List 
                  <Link to="/user/create" className="btn btn-sm btn-primary  ml-2">Create User</Link>   
                  </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">DataTables</li>
                </ol>
              </div>
            </div>
          </div>
        </section> 
        <div className="row">
          <div className="col-12">
            <div className="card">
              
              <div className="card-body table-responsive p-0">
                <table className="table table-hover text-nowrap">
                  <thead>
                    <tr>
                        <th>#</th>
                        <th>User Name</th> 
                        <th>Email</th> 
                        <th>Phone</th> 
                        <th>View</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {filterScrap.length > 0 ? filterScrap.map((data, index) => {
                      return (
                        <tr> 
                          <td>{data.id}</td> 
                          <td>{data.name}</td> 
                          <td>{data.email}</td> 
                          <td>{data.phone}</td> 
                          <td><Link className="btn btn-sm btn-info mr-2" to={{pathname: "/user/edit", state: {user: data}}}>Edit</Link></td> 
                        </tr>
                      )
                    })
                      :
                      <tr>
                        <td colSpan={8}>No result found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
