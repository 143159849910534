import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { scrapService,configService,customerService } from '../../_services';  
import PrintReceipt from '../../component/PrintReceipt';   
import axios from "axios";
import PrintComponents from "react-print-components";
export default class TransicationReceipt extends Component { 
 constructor(props) {
    super(props); 
      this.state = {
           scrap_id:'', 
           custom_scraps:[],
           scrapTotal:'', 
           owner_detail:'',
           trasaction_detail:'',
      }  
    };  
  async componentDidMount(){
     let checkTokenData = localStorage.getItem("userToken");
        if (this.props.location.state) { 
            const scrapId =  this.props.location.state.scrap_id;
              this.setState({
                scrap_id:this.props.location.state.scrap_id,
                custom_scraps:this.props.location.state.custom_scraps, 
              });
              scrapService.getOrderRecept(scrapId)
              .then(async (result) => {
                  let responseJson = result;  
                  if(responseJson.status){
                        let finalData = responseJson.data.data;  
                        this.setState({
                          owner_detail:finalData.owner_detail,
                          trasaction_detail:finalData.trasaction_detail,
                          custom_scraps:finalData.custom_scraps
                        }); 
                  } 
              });
        }else{
          this.props.history.push(`/scrap-quote`);
        }  
  }  
  render() { 
    return (
      <> 
          <PrintReceipt data={this.state}/> 
      </>
    );
  }
}
