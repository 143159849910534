import React,{ Component } from 'react';
import axios from "axios"; 
import { Link } from 'react-router-dom';  

export default class messageCount extends Component{
    state = {
        msgCount: 0, 
    } 
    async componentDidMount() { 
        
        // db.ref('tickets/').orderByChild("status").equalTo("Pending").on("value", function(ysnapshot) {
        //     var mcount = ysnapshot.numChildren(); 
        //     this.setState({msgCount:5});
        // });
        /*try {  
            let ticketSlug = 'tickets/';  
            db.ref(ticketSlug).orderByChild("status").equalTo("Pending").on("value", snapshot => { 
              this.setState({ msgCount:snapshot.numChildren() }); 
            }); 
          } catch (error) {
            this.setState({ readError: error.message });
          }*/
    } 
    render(){
            const {categoryArray} = this.state; 
            return(
                <li className="nav-item"> 
                    <Link className="nav-link massege" 
                    to={{   pathname: `/support-tickets`, 
                    }}>
                    <i className="fa fa-envelope" />
                    <span className="badge">{this.state.msgCount}</span></Link>
                </li>
            )
    }  
} 